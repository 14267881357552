import { StageFeatureStoreService } from 'src/app/core/services/stores/stage-feature-store.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { GlobalConstants } from 'src/app/core/global-constants';
import { Component, ViewChild, OnInit, OnDestroy, SecurityContext, ChangeDetectorRef, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as stages from '../../../assets/stages_new_prospespects';
import * as rottenTimeModalOptions from 'src/app/shared/custom/modal-options/custom-rotten-time.options';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment"
import printJS from 'print-js'
import { EaFollowUpComponent } from '../ea-follow-up/ea-follow-up.component';
import { ConfirmPersonDataType } from 'src/app/core/enums/ConfirmPersonDataType';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { MultiSelectDataType } from 'src/app/core/enums/MultiSelectDataType';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { ValueMode } from 'src/app/core/enums/ValueMode';
import { Phone, Email, Deal } from 'src/app/core/models/Deal';
import { DealDetails } from 'src/app/core/models/DealDetails';
import { HistoryRecord } from 'src/app/core/models/DealHistory';
import { CommonConnections } from 'src/app/core/models/Participant';
import { Person } from 'src/app/core/models/Person';
import { SameBuildingConnections } from 'src/app/core/models/SameBuilding';
import { User } from 'src/app/core/models/UserModel';
import { DealService } from 'src/app/core/services/deals.service';
import { HotlistService } from 'src/app/core/services/hotlist.service';
import { PreviousRouteService } from 'src/app/core/services/previousRoute.service';
import { StageService } from 'src/app/core/services/stage.service';
import { UsersService } from 'src/app/core/services/users.service';
import { DateHelper } from 'src/app/core/utils/date.helper';
import { UtilsHelper } from 'src/app/core/utils/utils.helper';
import { CustomAddressConfirmComponent } from 'src/app/shared/custom/custom-address-confirm/custom-address-confirm.component';
import { CustomUploadEaFollowUpImageModalComponent } from 'src/app/shared/custom/custom-ea-follow-up-image-upload-modal/custom-ea-follow-up-image-upload-modal.component';
import { CustomKillRecordModalComponent } from 'src/app/shared/custom/custom-kill-record-modal/custom-kill-record-modal.component';
import { CustomMeetingConfirmComponent } from 'src/app/shared/custom/custom-meeting-confirm/custom-meeting-confirm.component';
import { CustomMeetingDateModalComponent } from 'src/app/shared/custom/custom-meeting-date/custom-meeting-date.component';
import { CustomMeetingComponent } from 'src/app/shared/custom/custom-meeting/custom-meeting.component';
import { CustomReferralModalComponent } from 'src/app/shared/custom/custom-referral-modal/custom-referral-modal.component';
import { CustomRottenTimeModalComponent } from 'src/app/shared/custom/custom-rotten-time-modal/custom-rotten-time-modal.component';
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';
import { CustomUploadWardrobePlanModalComponent } from 'src/app/shared/custom/custom-wardrobe-plan-upload-modal/custom-wardrobe-plan-upload-modal.component';
import { MeetingPrepComponent } from 'src/app/shared/custom/meeting-prep/meeting-prep.component';
import { KillRecordOptions } from 'src/app/shared/custom/modal-options/kill-record.options';
import { WardrobePlanComponent } from 'src/app/shared/custom/wardrobe-plan/wardrobe-plan.component';
import { NoteComponent } from 'src/app/shared/notes/note-table.component';
import { DealUtil } from 'src/app/core/utils/deal.util';
import { Stage } from 'src/app/core/models/Stage';
import { CmsClientModel } from 'src/app/core/models/cms-client.model';
import { CmsService } from 'src/app/core/services/cms.service';
import { Tab } from 'src/app/core/models/tab.model';
import { ClientCardComponent } from 'src/app/clients-card/client-card.component';
import { MatDialog } from '@angular/material/dialog';
import { NoteService } from 'src/app/core/services/note.service';
import { PersonPropertiesToUpdate } from 'src/app/core/enums/PersonPropertiesToUpdate';
import { CustomContactsModalComponent } from 'src/app/shared/custom/custom-contacts-modal/custom-contacts-modal.component';
import { CallModalComponent } from 'src/app/shared/call-modal/call-modal.component';
import { CallsService } from 'src/app/core/services/calls.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
    selector: 'pd-deals-details',
    templateUrl: './deals-details.component.html',
    styleUrls: ['./deals-details.component.scss']
})

export class DealsDetailsComponent implements OnInit, OnDestroy {
    public spinner = true;
    public selectDataTypes = MultiSelectDataType
    public RolesEnum = RolesEnum;
    public newProspectsStageName: string
    public ALL_STAGES: any = GlobalConstants.ALL_STAGES;
    public STAGES_AND_PIPELINES: any = stages;
    public DealHelper = DealUtil;
    public stages = stages;
    public pipelines = [];
    public id: number;
    public item: Person;
    public dealsPerson: DealDetails;
    public phone: any;
    public partnerPhone: Phone = null;
    public clickEdit = false;
    public addCompanyValue = false;
    public addLocationValue = false;
    public addFirstNameValue = false;
    public addLastNameValue = false;
    public addCompanyAddressValue = false;
    public addHomeAddressValue = false;
    public addPersonWhoReferredValue = false;
    public addlinkedinUrlValue = false;
    public addPhoneValue = false;
    public addEmailValue = false;
    public addPartnerFirstNameValue = false;
    public addPartnerLastNameValue = false;
    public addPartnerEmailValue = false;
    public addPartnerPhoneValue = false;
    public selectedPhoneLabel = [];
    public defaultPhoneArray = [];
    public defaultEmailArray = [];
    public defaultPartnerPhoneArray = [];
    public isAdmin = false;
    public isSystemAccount = false;
    public users = [];
    public filteredUsers = [];
    public gender: string;
    public isUrlExist = false;
    public urlError: string;
    public pipelineId: number;
    public isShowOwner = true;
    public isNotUseAsCommonConnection = false;
    public isDontSendPromotionalEmails = false;
    public isDontSendPartnerEmails = false;
    public isDontSendWhatsapp = false;
    public isDontSendSms = false;
    public isPartnerRepliedToBDayEmail = false;
    public genderList = ['All', 'Male', 'Female'];
    public groupList = [];
    public participants: CommonConnections;
    public sameBuildings: SameBuildingConnections;
    public dealHistory: Array<HistoryRecord>;
    public isMyDealOrMySubordinate: boolean;
    public isFilterUserIsRepoAccount: boolean;
    public isShowStageMessage = false;

    public currentUser: User;
    public currentDeal: DealDetails;
    public isAllowedFieldsChange: boolean;
    public bookedBy = "";
    public userFilter: any;
    public isAllowedToChangeStage = true;
    public clientsByRoles = stages.CLIENTS_BY_ROLES;
    public isCampaignAccount = false;
    public frameurl: string;
    public frameContent = null;
    public isFrameTabActive = false;
    public disableChangeOwner: boolean = false;
    cmsClient: CmsClientModel = null;
    public isClientCards = false;
    public isChat = false;
    public isOrderShippedChanging = false;
    public isFrameAlreadyStarted = false;
    public isShowValidationMessages = false;
    public partnerPhoneArray: Phone[] = [];

    public confirmPersonDataType = ConfirmPersonDataType;
    public personPropertiesToUpdate = PersonPropertiesToUpdate;
    @ViewChild('referralModal') referralModal: CustomReferralModalComponent;
    @ViewChild('killRecordModal') killRecordModal: CustomKillRecordModalComponent;
    @ViewChild('isIncreasedRottingModal') isIncreasedRottingModal: CustomKillRecordModalComponent;
    @ViewChild('isForClothierModal') isForClothierModal: CustomKillRecordModalComponent;
    @ViewChild('isForClothierCancelModal') isForClothierCancelModal: CustomKillRecordModalComponent;
    @ViewChild('meetingDateModal') meetingDateModal: CustomMeetingDateModalComponent;
    @ViewChild('rottenTimeModal') rottenTimeModal: CustomRottenTimeModalComponent;
    @ViewChild('notesTable') notesTable: NoteComponent;
    @ViewChild('eaFollowUpTable') eaFollowUpTable: EaFollowUpComponent;
    @ViewChild('wardrobePlan') wardrobePlan: WardrobePlanComponent;
    @ViewChild('meetingPrep') meetingPrep: MeetingPrepComponent;
    @ViewChild('uploadWardrobePlan') uploadWardrobePlan: CustomUploadWardrobePlanModalComponent;
    @ViewChild('eaFollowUpImageUpload') eaFollowUpImageUpload: CustomUploadEaFollowUpImageModalComponent;
    @ViewChild('trackingLinkModal') trackingLinkModal: CustomKillRecordModalComponent;
    @ViewChild('clientCard', { static: false }) clientCard: ClientCardComponent;

    public killRecordOptions: KillRecordOptions;
    public isIncreasedRottingOptions: KillRecordOptions;
    public isIncreasedRottingMessageText = "Do you want to increase rotten time to 180 days?";
    public isForClothierOptions: KillRecordOptions;
    public isForClothierMessageText = "Should only Clothier contact this client?";
    public isForClothierCancelOptions: KillRecordOptions;
    public isForClothierCancelMessageText = "Are you sure?";
    public trackingLinkOptions: KillRecordOptions;
    public trackingLinkMessageText = "Please enter the tracking link";
    public trackingLinkPlaceholder = "Link";
    public trackingLinkConfirmButtonText = "Save";
    public hideClientNameText = "Client at";
    private previousStageId: number;
    public addressValidationTooltip = UtilsHelper.addressValidationMessage;
    public hasAnyChats: boolean = false;

    public tabs: Tab[];

    constructor(private stageService: StageService, public dealService: DealService, public usersService: UsersService, private noteService: NoteService, private cdRef: ChangeDetectorRef,
        private hotlistService: HotlistService, private route: ActivatedRoute, private router: Router, private cmsService: CmsService,
        private sanitizer: DomSanitizer, private previousRouteService: PreviousRouteService, private callService: CallsService, private notificationService: NotificationService,
        private userContextService: UserContextService, private stageFeatureStoreService: StageFeatureStoreService, private dialog: MatDialog) {
        this.frameurl = '';
        this.newProspectsStageName = stages.NewProspectsPipeline.name
    }

    async ngOnInit(): Promise<void> {
        if (!this.stageFeatureStoreService.allStage.value) {
            this.stageFeatureStoreService.setAllStage(stages.STAGES_FOR_ALL_PIPELINES as Stage[]);
        }
        this.currentUser = this.userContextService.user.value;

        this.route.params.subscribe(async params => {
            this.id = +params['id'];
            this.item = null;
        });
        this.users = await this.usersService.getUsers();
        await this.getDealById();
        this.isAdmin = this.currentUser && this.currentUser.is_admin;

        if (this.currentUser && this.currentUser.role_id === RolesEnum.CampaignAccount) {
            this.isCampaignAccount = true;
        }
        this.isAllowedFieldsChange = this.getIsAllowedToChangeFields(this.currentDeal, this.currentUser);
        this.updateStagesAndPipelines();
        if (this.currentDeal.bookedById) {
            this.bookedBy = this.currentDeal.bookedByName
        }
        this.filteredUsers = this.users.filter(item => item.role_id !== RolesEnum.EA && item.role_id !== RolesEnum.EaCaller);
        this.clothierMeetingConfirmFilter();
        this.setUpDialogOptions();
        this.hasAnyChats = await this.dealService.hasAnyChats(this.currentDeal.id);
        this.tabs = [
            new Tab({ isActive: true, name: "Deal", id: 1 }),
            new Tab({ isActive: false, name: "Client Card of " + this.item.firstName + ' ' + this.item.lastName, id: 2 }),
            new Tab({ isActive: false, name: "CMS", id: 3 }),
            new Tab({ isActive: false, name: "Map", id: 4 }),
            new Tab({ isActive: false, name: "Chat with " + this.item.firstName + ' ' + this.item.lastName, id: 5 })
        ];
    }

    ngAfterViewInit() {
        this.spinner = false;
    }

    public onTabClick(tab) {
        if (tab.id == 3) {
            window.open(this.frameurl);
            return;
        }
        this.tabs.find(x => x.isActive == true).isActive = false;
        tab.isActive = true;
    }

    public isTabVisible(tabId: number): boolean {
        if (tabId == 1) {
            return true;
        }
        if (tabId == 2 && this.isClientCards && this.item) {
            return true;
        }
        if (tabId == 3 && this.frameurl
            && ((this.currentUser.isSeeOthersCms && this.currentUser.role_id === RolesEnum.Admin)
                || this.isMyDealOrMySubordinate
                || this.currentUser.role_id === RolesEnum.MasterLead
                || this.currentUser.role_id === RolesEnum.EA
                || this.currentUser.role_id === RolesEnum.EaCaller
            )) {
            return true;
        }
        if (tabId == 4 && this.dealsPerson && (this.isAdmin || this.isMyDealOrMySubordinate) &&
            (this.isClient || (this.currentDeal.stage_id === this.ALL_STAGES.RefVM1
                || this.currentDeal.stage_id === this.ALL_STAGES.RefVM2
                || this.currentDeal.stage_id === this.ALL_STAGES.RefVM3
                || this.currentDeal.stage_id === this.ALL_STAGES.Referral
                || this.currentDeal.stage_id === this.ALL_STAGES.Meeting
                || this.currentDeal.stage_id === this.ALL_STAGES.Float))) {
            return true;
        }
        if (tabId == 5 && this.isChat && ((this.item && this.item.phone && this.item.phone.length > 0 && this.item.phone[0].value) || this.hasAnyChats)) {
            return true;
        }

        return false;
    }

    private getAvailiableStageOptionsForDeal(allStageFeatureItems: Stage[], currentDeal: DealDetails, currentUser: User, dealOwner: User): Stage[] {
        if (!currentUser || !currentUser.role_id || !currentDeal)
            return [];

        let stage = allStageFeatureItems.find(s => s.id === currentDeal.stage_id);

        if (currentDeal.pipeline_id !== stages.Pipelines.ClothierMeetingConfirm)
            allStageFeatureItems = allStageFeatureItems.filter(x => x.pipeline_id !== stages.Pipelines.ClothierMeetingConfirm)
        else
            return allStageFeatureItems.filter(x => x.pipeline_id === stages.Pipelines.ClothierMeetingConfirm && stages.MEETING_CONFIRM_MOVE_RULES[stage.id].some(xx => xx === x.id))
        if (currentUser.is_admin && currentDeal.pipeline_id === stages.Pipelines.ClothierMeetingConfirm)
            return allStageFeatureItems && allStageFeatureItems.filter(s => s.pipeline_id === stages.Pipelines.ClothierMeetingConfirm)

        if (currentDeal.pipeline_id !== stages.Pipelines.ClothierContactClients) {
            if (!(currentUser.role_id === RolesEnum.MasterLead && currentDeal.pipeline_id === stages.Pipelines.Clients)) {
                allStageFeatureItems = allStageFeatureItems.filter(i => i.pipeline_id !== stages.Pipelines.ClothierContactClients);
            }
        }
        else {
            if (currentUser.role_id !== RolesEnum.MasterLead) {
                allStageFeatureItems = allStageFeatureItems.filter(s => (s.pipeline_id === stages.Pipelines.ClothierContactClients && stages.CLOTHIERCONTACTCLIENTS_MOVE_RULES[stage.id].some(ss => ss === s.id)))
            } else {
                allStageFeatureItems = allStageFeatureItems.filter(s => (s.pipeline_id === stages.Pipelines.ClothierContactClients && stages.CLOTHIERCONTACTCLIENTS_MOVE_RULES_MasterLead[stage.id].some(ss => ss === s.id))
                    || (currentUser.role_id === RolesEnum.MasterLead && (s.pipeline_id === stages.Pipelines.Clients || s.pipeline_id === stages.Pipelines.OpenOrders)
                        && s.id !== this.ALL_STAGES.NewOrder
                        && s.id !== this.ALL_STAGES.Less3Weeks
                        && s.id !== this.ALL_STAGES.Less6Weeks
                        && s.id !== this.ALL_STAGES.Less9Weeks
                        && s.id !== this.ALL_STAGES.OverdueLess12Weeks
                        && s.id !== this.ALL_STAGES.OverdueLess16Weeks
                        && s.id !== this.ALL_STAGES.OverdueLess24Weeks
                        && s.id !== this.ALL_STAGES.WriteOff
                        && s.id !== this.ALL_STAGES.CashedOut))
            }
        }

        if (currentDeal.pipeline_id !== stages.Pipelines.Clients && currentDeal.pipeline_id !== stages.Pipelines.OpenOrders) {
            if (currentUser.role_id !== RolesEnum.MasterLead || currentDeal.pipeline_id !== stages.Pipelines.ClothierContactClients)
                allStageFeatureItems = allStageFeatureItems.filter(i => i.pipeline_id !== stages.Pipelines.Clients && i.pipeline_id !== stages.Pipelines.OpenOrders)
            if (!currentUser.is_admin) {
                allStageFeatureItems = allStageFeatureItems.filter(s => (currentUser.role_id == RolesEnum.DirectMailAccount || s.id !== this.ALL_STAGES.DM)
                    && (s.id !== this.ALL_STAGES.Float || currentUser.role_id == RolesEnum.Clothier))
            }

            if (currentDeal.pipeline_id === stages.Pipelines.NewProspects && currentUser.role_id === RolesEnum.BB) {
                allStageFeatureItems = allStageFeatureItems.filter(s => s.id !== this.ALL_STAGES.Referral)
            }

            if (currentDeal.stage_id === this.ALL_STAGES.Meeting && currentUser.role_id !== RolesEnum.Admin && currentUser.role_id !== RolesEnum.MasterLead) {
                allStageFeatureItems = allStageFeatureItems.filter(s => s.id !== this.ALL_STAGES.NG)
            }

            if (currentDeal.stage_id !== this.ALL_STAGES.Meeting) {
                allStageFeatureItems = allStageFeatureItems.filter(s => s.id !== this.ALL_STAGES.ClientSaved)
            }

            if (currentDeal.stage_id === this.ALL_STAGES.Referral) {
                allStageFeatureItems = allStageFeatureItems.filter(s => s.id !== this.ALL_STAGES.VM1 && s.id !== this.ALL_STAGES.VM2 && s.id !== this.ALL_STAGES.VM3)
                if (!currentUser.is_admin)
                    allStageFeatureItems = allStageFeatureItems.filter(s => s.id !== this.ALL_STAGES.Cold && s.id !== this.ALL_STAGES.Linkedin && s.id !== this.ALL_STAGES.SameBuilding && s.id !== this.ALL_STAGES.SM && s.id !== this.ALL_STAGES.SM1 && s.id !== this.ALL_STAGES.Float && s.id !== this.ALL_STAGES.Resched)
            }
            else if (currentDeal.stage_id !== this.ALL_STAGES.RefVM1 && currentDeal.stage_id !== this.ALL_STAGES.RefVM2 && currentDeal.stage_id !== this.ALL_STAGES.RefVM3 && !currentUser.is_admin) {
                allStageFeatureItems = allStageFeatureItems.filter(s => s.id !== this.ALL_STAGES.RefVM1 && s.id !== this.ALL_STAGES.RefVM2 && s.id !== this.ALL_STAGES.RefVM3)
            }

            if ((currentDeal.stage_id === this.ALL_STAGES.RefVM1 || currentDeal.stage_id === this.ALL_STAGES.RefVM2 || currentDeal.stage_id === this.ALL_STAGES.RefVM3) && !currentUser.is_admin) {
                allStageFeatureItems = allStageFeatureItems.filter(s => stages.PROSPECTS_MOVE_RULES[stage.id].some(ss => ss === s.id));
            }
        } else {
            if (!currentUser.is_admin) {
                allStageFeatureItems = allStageFeatureItems.filter(i => i.pipeline_id !== stages.Pipelines.ClothierContactClients);
            }
            if (currentUser.role_id !== RolesEnum.MasterLead) {
                allStageFeatureItems = allStageFeatureItems.filter(s => (s.pipeline_id === stages.Pipelines.Clients || s.pipeline_id === stages.Pipelines.OpenOrders) && stages.CLIENT_MOVE_RULES[stage.id].some(ss => ss === s.id));
            } else {
                let currentStage = allStageFeatureItems.find(s => s.id === currentDeal.stage_id);
                if (currentStage) {
                    allStageFeatureItems = allStageFeatureItems.filter(s =>
                        (s.pipeline_id === stages.Pipelines.Clients
                            || s.pipeline_id === stages.Pipelines.OpenOrders
                            || s.pipeline_id === stages.Pipelines.ClothierContactClients
                        )
                        && stages.CLIENT_MOVE_RULES_MasterLead[currentStage.id].some(ss => ss === s.id));
                }
            }

            if (stage && (currentUser.role_id === RolesEnum.Admin
                || currentUser.role_id === RolesEnum.EA
                || currentUser.role_id === RolesEnum.EaCaller)) {
                //PD-1713 - Ability to change status from "Recently Contacted" to "EA Current Meeting"
                if (stage.id === this.ALL_STAGES.RecentlyContacted) {
                    allStageFeatureItems.push(stages.STAGES_FOR_ALL_PIPELINES.find(s => s.id === this.ALL_STAGES.EACurrentMeeting) as Stage);
                }
            }

            if (stage && (currentUser.role_id === RolesEnum.Admin
                || currentUser.role_id === RolesEnum.Clothier)) {
                //PD-1713 - Ability to change status from "Recently Contacted" to "Current Meeting"
                if (stage.id === this.ALL_STAGES.RecentlyContacted) {
                    allStageFeatureItems.push(stages.STAGES_FOR_ALL_PIPELINES.find(s => s.id === this.ALL_STAGES.CurrentMeeting) as Stage);
                }
            }

            if (stage && (currentUser.role_id === RolesEnum.MasterLead
                || currentUser.role_id === RolesEnum.Admin
                || currentUser.role_id === RolesEnum.Clothier)) {
                //PD-1745 - Ability to change status from "CashedOut" to "Recently Contacted"
                if (stage.id === this.ALL_STAGES.CashedOut) {
                    allStageFeatureItems.push(stages.STAGES_FOR_ALL_PIPELINES.find(s => s.id === this.ALL_STAGES.RecentlyContacted) as Stage);
                }
            }
        }

        let allowedStagesByRole = stages.STAGES_BY_ROLES_VISIBILITY[RolesEnum[dealOwner && dealOwner.role_id || null]];
        return allStageFeatureItems.filter(s => allowedStagesByRole.some(ss => ss === s.name));
    };

    private getIsAllowedToChangeFields(currentDeal: DealDetails, currentUser: User): boolean {
        let result = currentUser && currentDeal &&
            (
                (currentUser.role_id !== RolesEnum.BB
                    && (currentUser.role_id === RolesEnum.MasterLead || currentUser.role_id === RolesEnum.Admin || currentUser.role_id === RolesEnum.Clothier
                        || currentDeal.pipeline_id === stages.Pipelines.Clients
                        || currentDeal.pipeline_id === stages.Pipelines.OpenOrders
                        || ((currentUser.role_id === RolesEnum.EA || currentUser.role_id === RolesEnum.EaCaller) && currentDeal.pipeline_id === stages.Pipelines.ClothierContactClients)))
                || (currentUser.role_id === RolesEnum.BB && (currentDeal.pipeline_id === stages.Pipelines.NewProspects || currentDeal.pipeline_id === stages.Pipelines.ClothierMeetingConfirm))
            )
        return result
    }

    private setUpDialogOptions() {
        this.killRecordOptions = new KillRecordOptions()
        this.isIncreasedRottingOptions = new KillRecordOptions()
        this.isIncreasedRottingOptions.MessageText = this.isIncreasedRottingMessageText
        this.isIncreasedRottingOptions.MessageTextForRegularUser = this.isIncreasedRottingMessageText
        this.isForClothierOptions = new KillRecordOptions()
        this.isForClothierOptions.MessageText = this.isForClothierMessageText
        this.isForClothierOptions.MessageTextForRegularUser = this.isForClothierMessageText
        this.isForClothierCancelOptions = new KillRecordOptions()
        this.isForClothierCancelOptions.MessageText = this.isForClothierCancelMessageText
        this.isForClothierCancelOptions.MessageTextForRegularUser = this.isForClothierCancelMessageText
        this.trackingLinkOptions = new KillRecordOptions()
        this.trackingLinkOptions.MessageText = this.trackingLinkMessageText
        this.trackingLinkOptions.MessageTextForRegularUser = this.trackingLinkMessageText
        this.trackingLinkOptions.ConfirmBtnText = this.trackingLinkConfirmButtonText
    }

    private setUserFilter() {
        if (this.dealsPerson && this.dealsPerson.user_id != null) {
            const user = this.users.find(x => x.id === this.dealsPerson.user_id);
            if (user) {
                this.userFilter = user;
                this.isFilterUserIsRepoAccount = user.isRepoAccount;
            }
        }
    }

    ngOnDestroy() {
    }

    private setStateChangeAllowance() {
        if (this.currentUser) {
            const role = RolesEnum[this.currentUser.role_id];
            let stage = stages.STAGES_FOR_ALL_PIPELINES.find(st => st.id === this.dealsPerson.stage_id);
            if (stage) {
                this.isAllowedToChangeStage = stages.STAGES_BY_ROLES_VISIBILITY[RolesEnum[this.userFilter.role_id]].some(st => st === stage.name) ||
                    (this.currentUser.is_admin && this.currentDeal.pipeline_id === stages.Pipelines.ClothierMeetingConfirm);
                if (!this.isAllowedToChangeStage && (role === RolesEnum[RolesEnum.MasterLead] || role === RolesEnum[RolesEnum.SystemAccount] ||
                    role === RolesEnum[RolesEnum.Admin]) && (stage.id === this.ALL_STAGES.ColdLOOKUP || stage.id === this.ALL_STAGES.LinkedinLOOKUP)) {
                    this.isAllowedToChangeStage = true;
                }
            } else {
                this.isAllowedToChangeStage = false;
            }
            if ((this.currentUser.role_id === RolesEnum.EA || this.currentUser.role_id === RolesEnum.EaCaller) && (this.dealsPerson.pipeline_id === stages.Pipelines.NewProspects || this.dealsPerson.pipeline_id === stages.Pipelines.ClothierContactClients)) {
                this.isAllowedToChangeStage = false;
                this.disableChangeOwner = true;
            }
        }
    }

    private async refreshDataForStore(): Promise<void> {
        await this.getDealById();
    }

    private async getDealById(): Promise<void> {
        this.spinner = true;
        const result = await this.dealService.getDealById(this.id);
        this.dealsPerson = result;
        this.currentDeal = result;
        this.setUserFilter();
        this.updateStagesAndPipelines();

        if (this.currentDeal.bookedById) {
            this.bookedBy = this.currentDeal.bookedByName
        }

        this.previousStageId = this.dealsPerson.stage_id;
        this.updateIsMyDeal();
        this.participants = await this.dealService.getParticipants(this.dealsPerson.id, this.dealsPerson.personId);
        this.sameBuildings = await this.dealService.getSameBuildings(this.dealsPerson.personId);
        await this.getDealHistory();

        if (this.notesTable) {
            await this.notesTable.loadNotes();
        }

        this.pipelineId = this.dealsPerson.pipeline_id;
        this.disableChangeOwner = this.dealsPerson.stage_id === this.ALL_STAGES.Meeting
            || this.dealsPerson.stage_id === this.ALL_STAGES.CurrentMeeting
            || this.dealsPerson.stage_id === this.ALL_STAGES.ClientSaved
            || this.isClient;

        if (this.dealsPerson.clientId
            && (this.isAdmin || this.isMyDealOrMySubordinate || (this.currentUser.role_id != RolesEnum.BB && this.currentUser.role_id != RolesEnum.Clothier))
            && (this.dealsPerson.stage_id === this.ALL_STAGES.Meeting || this.dealsPerson.stage_id === this.ALL_STAGES.ClientSaved || this.isClient)) {
            this.frameurl = (environment.newCmsIntegration) ?
                this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(environment.cmsDealFrameUrl + "Id=" + this.dealsPerson.clientId)) :
                this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(environment.cmsDealFrameUrl + "&id=" + this.dealsPerson.clientId));
        }

        if ((this.isAdmin
            || this.isMyDealOrMySubordinate
            || (this.currentUser.role_id != RolesEnum.BB && this.currentUser.role_id != RolesEnum.Clothier))
            && (this.dealsPerson.stage_id === this.ALL_STAGES.Meeting || this.dealsPerson.stage_id === this.ALL_STAGES.ClientSaved || this.isClient)) {
            this.isClientCards = true;
        }

        if (
            (this.isCandidate && this.currentUser.role_id == RolesEnum.MasterLead)
            ||
            (this.isClient
                && (this.currentUser.role_id == RolesEnum.MasterLead
                    || this.currentUser.role_id == RolesEnum.EA
                    || this.currentUser.role_id == RolesEnum.EaCaller
                    || (this.currentUser.role_id === RolesEnum.Admin && this.currentUser.isWhatsappChat)
                    || ((this.currentUser.role_id === RolesEnum.Admin || this.currentUser.role_id === RolesEnum.Clothier) && this.isMyDealOrMySubordinate)))
        ) {
            this.isChat = true;
        }

        this.setStateChangeAllowance();

        if (this.dealsPerson.personId) {
            this.fillPersonData();
        }
        this.setOwnersByStage(this.dealsPerson.stage_id);

        if (this.dealsPerson && this.dealsPerson.clientId) {
            this.cmsClient = await this.cmsService.getClientById(this.dealsPerson.clientId);
        }

        if (this.pipelineId === stages.ClientsPipeline.id || this.pipelineId === stages.OpenOrders.id) {
            this.groupList = await this.dealService.getClientGroups();
        }

        this.spinner = false;
        this.cdRef.detectChanges();
    }

    private clothierMeetingConfirmFilter() {
        if (this.dealsPerson && this.dealsPerson.pipeline_id === stages.Pipelines.ClothierMeetingConfirm)
            this.filteredUsers = this.filteredUsers.filter(x => x.role_id === RolesEnum.Clothier || x.role_id === RolesEnum.Admin || x.role_id === RolesEnum.MasterLead)
    }

    /**
     * Update information
     * edit deal
     * @param {any} id selected person
     * @param {string} name name of edited line
     * @param {any} notes modified value
     * @memberof DealsDetailsComponent
     */
    public updatePersonWhoReferred(personWhoReferred: any) {
        this.dealsPerson.personWhoReferred = personWhoReferred;
        this.addPersonWhoReferredValue = !this.addPersonWhoReferredValue;
        this.dealService.updatePersonWhoReferred(this.dealsPerson.id, personWhoReferred);
    }

    private checkAddressIsValid(address: string) {
        return UtilsHelper.checkAddressIsValid(address);
    }

    public updateInformation(id: any, propertyId: PersonPropertiesToUpdate, value: any) {
        this.isUrlExist = false;

        if (this.checkIsValidValueToUpdate(propertyId, value)) {
            this.dealService.updateInformation(id, propertyId, value, this.dealsPerson.id).then(
                () => {
                },
                (error) => {
                    if (error.status === 400) {
                        this.isUrlExist = true;
                        this.urlError = error.error.error;
                    }
                }
            );
            switch (propertyId) {
                case PersonPropertiesToUpdate.CompanyAddress: {
                    this.item.companyAddress = value;
                    this.addCompanyAddressValue = !this.addCompanyAddressValue;
                    break;
                }
                case PersonPropertiesToUpdate.HomeAddress: {
                    this.item.homeAddress = value;
                    this.addHomeAddressValue = !this.addHomeAddressValue;
                    break;
                }
                case PersonPropertiesToUpdate.Company: {
                    this.item.company = value;
                    this.addCompanyValue = !this.addCompanyValue;
                    break;
                }
                case PersonPropertiesToUpdate.Location: {
                    this.item.location = value;
                    this.addLocationValue = !this.addLocationValue;
                    break;
                }
                case PersonPropertiesToUpdate.Position: {
                    this.item.position = value;
                    this.dealsPerson.position = value;
                    this.positionMode = ValueMode.Label;
                    this.selectedPosition = "";
                    break;
                }
                case PersonPropertiesToUpdate.LinkedinURL: {
                    this.item.linkedinUrl = value;
                    this.addlinkedinUrlValue = !this.addlinkedinUrlValue;
                    break;
                }
                case PersonPropertiesToUpdate.FirstName: {
                    this.item.firstName = value;
                    this.addFirstNameValue = !this.addFirstNameValue;
                    break;
                }
                case PersonPropertiesToUpdate.LastName: {
                    this.item.lastName = value;
                    this.addLastNameValue = !this.addLastNameValue;
                    break;
                }
                case PersonPropertiesToUpdate.PartnerFirstName: {
                    this.item.partnerFirstName = value;
                    this.addPartnerFirstNameValue = !this.addPartnerFirstNameValue;
                    break;
                }
                case PersonPropertiesToUpdate.PartnerLastName: {
                    this.item.partnerLastName = value;
                    this.addPartnerLastNameValue = !this.addPartnerLastNameValue;
                    break;
                }
                case PersonPropertiesToUpdate.PartnerEmail: {
                    this.item.partnerEmail = value;
                    this.addPartnerEmailValue = !this.addPartnerEmailValue;
                    break;
                }
                case PersonPropertiesToUpdate.IsPartnerRepliedToBDayEmail: {
                    this.item.isPartnerRepliedToBDayEmail = value
                    break;
                }
            }
        }
    }

    public updatePersonContactsInfo(id: any, propertyId: PersonPropertiesToUpdate, value: any) {
        this.isUrlExist = false;

        if (this.checkIsValidValueToUpdate(propertyId, value)) {
            if (propertyId === PersonPropertiesToUpdate.PartnerPhone) {
                let partnerPhone: string = '';

                if (value.length !== 0) {
                    partnerPhone = value[0].value;
                }

                this.dealService.updateInformation(id, propertyId, partnerPhone, this.dealsPerson.id).then(
                    () => {
                        if (propertyId === PersonPropertiesToUpdate.PartnerPhone) {
                            this.updateTineZone();
                        }
                    },
                    (error) => {
                        if (error.status === 400) {
                            this.isUrlExist = true;
                            this.urlError = error.error.error;
                        }
                    }
                );

                this.addPartnerPhoneValue = !this.addPartnerPhoneValue;
                this.partnerPhoneArray = value;

                for (let i = 0; i < this.partnerPhoneArray.length; i++) {
                    if (!this.partnerPhoneArray[i].value || this.partnerPhoneArray[i].value.trim() === '') {
                        this.partnerPhoneArray.splice(i, 1);
                        i--;
                    }
                }

                if (this.partnerPhoneArray.length == 0) {
                    this.addEmptyPartnerPhone();
                }

                this.defaultPartnerPhoneArray = UtilsHelper.deepClone(this.partnerPhoneArray);
            }
            else {
                this.dealService.updateInformation(id, propertyId, value, this.dealsPerson.id).then(
                    () => {
                        if (propertyId === PersonPropertiesToUpdate.Phone) {
                            this.updateTineZone();
                        }
                    },
                    (error) => {
                        if (error.status === 400) {
                            this.isUrlExist = true;
                            this.urlError = error.error.error;
                        }
                    }
                );
                switch (propertyId) {
                    case PersonPropertiesToUpdate.Phone: {
                        this.addPhoneValue = !this.addPhoneValue;
                        this.item.phone = value;

                        for (let i = 0; i < this.item.phone.length; i++) {
                            if (!this.item.phone[i].value || this.item.phone[i].value.trim() === '') {
                                this.item.phone.splice(i, 1);
                                i--;
                            }
                        }

                        if (this.item.phone.length == 0) {
                            this.addEmptyPhone();
                        }

                        this.defaultPhoneArray = UtilsHelper.deepClone(this.item.phone);
                        break;
                    }
                    case PersonPropertiesToUpdate.Email: {
                        this.addEmailValue = !this.addEmailValue;
                        this.item.email = value;

                        for (let i = 0; i < this.item.email.length; i++) {
                            if (!this.item.email[i].value || this.item.email[i].value.trim() === '') {
                                this.item.email.splice(i, 1);
                                i--;
                            }
                        }

                        if (this.item.email.length == 0) {
                            this.addEmptyEmail();
                        }

                        this.defaultEmailArray = UtilsHelper.deepClone(this.item.email);
                        break;
                    }
                }
            }
        }
    }

    private checkIsValidValueToUpdate(propertyId: PersonPropertiesToUpdate, value: string): boolean {
        switch (propertyId) {
            case PersonPropertiesToUpdate.CompanyAddress: {
                if (!this.checkAddressIsValid(value)) {
                    this.isShowValidationMessages = true;
                    return false
                }

                break;
            }
            case PersonPropertiesToUpdate.HomeAddress: {
                if (!this.checkAddressIsValid(value)) {
                    this.isShowValidationMessages = true;
                    return false
                }

                break;
            }

            case PersonPropertiesToUpdate.FirstName:
            case PersonPropertiesToUpdate.LinkedinURL:
            case PersonPropertiesToUpdate.Company:
                return value.trim().length > 0;
        }

        return true;
    }

    private fillPersonData() {
        this.item = new Person();
        this.item.company = this.dealsPerson.company;
        this.item.companyAddress = this.dealsPerson.companyAddress;
        this.item.homeAddress = this.dealsPerson.homeAddress;
        this.item.email = this.dealsPerson.email;
        this.item.companyId = this.dealsPerson.companyId
        this.item.genderId = this.dealsPerson.genderId;
        this.item.id = this.dealsPerson.personId;
        this.item.name = this.dealsPerson.name;
        this.item.firstName = this.dealsPerson.firstName;
        this.item.lastName = this.dealsPerson.lastName;
        this.item.birthday = this.dealsPerson.birthday;
        this.item.isNotUseAsCommonConnection = this.dealsPerson.isNotUseAsCommonConnection;
        this.item.isDontSendPromotionalEmails = this.dealsPerson.isDontSendPromotionalEmails;
        this.item.isDontSendPartnerEmails = this.dealsPerson.isDontSendPartnerEmails;
        this.item.isDontSendWhatsapp = this.dealsPerson.isDontSendWhatsapp;
        this.item.isDontSendSms = this.dealsPerson.isDontSendSms;
        this.item.isPartnerRepliedToBDayEmail = this.dealsPerson.isPartnerRepliedToBDayEmail;
        this.item.linkedinUrl = this.dealsPerson.linkedin_URL;
        this.item.location = this.dealsPerson.location;
        this.item.locationId = this.dealsPerson.locationId;
        this.item.phone = this.dealsPerson.phone;
        this.item.position = this.dealsPerson.position;
        this.item.positionId = this.dealsPerson.positionId;

        if (this.item.email.length == 0) {
            this.addEmptyEmail();
        }
        if (this.item.phone.length == 0) {
            this.addEmptyPhone();
        }

        this.isNotUseAsCommonConnection = this.item.isNotUseAsCommonConnection;
        this.isDontSendPromotionalEmails = this.item.isDontSendPromotionalEmails;
        this.isDontSendPartnerEmails = this.item.isDontSendPartnerEmails;
        this.isDontSendWhatsapp = this.item.isDontSendWhatsapp;
        this.isDontSendSms = this.item.isDontSendSms;
        this.isPartnerRepliedToBDayEmail = this.item.isPartnerRepliedToBDayEmail;
        this.gender = this.getGenderLabel(this.item.genderId);

        this.defaultPhoneArray = UtilsHelper.deepClone(this.item.phone);
        this.defaultEmailArray = UtilsHelper.deepClone(this.item.email);
        this.partnerPhoneArray = [new Phone({ value: this.dealsPerson.partnerPhone ?? '' })]
        this.defaultPartnerPhoneArray = UtilsHelper.deepClone(this.partnerPhoneArray);

        this.item.partnerFirstName = this.dealsPerson.partnerFirstName;
        this.item.partnerLastName = this.dealsPerson.partnerLastName;
        this.item.partnerEmail = this.dealsPerson.partnerEmail;
        this.item.partnerToken = this.dealsPerson.partnerToken;
        this.item.partnerPhone = this.dealsPerson.partnerPhone;
    }

    /**
    * Get person data
    * get person information by id
    * @param {any} id selected person
    * @memberof DealsDetailsComponent
    */

    public getPersonData(id: any) {
        this.dealService.getPersonById(id).then(result => {
            this.item = result;
            this.isNotUseAsCommonConnection = this.item.isNotUseAsCommonConnection;
            this.isDontSendPromotionalEmails = this.item.isDontSendPromotionalEmails;
            this.isDontSendPartnerEmails = this.item.isDontSendPartnerEmails;
            this.isDontSendWhatsapp = this.item.isDontSendWhatsapp;
            this.isDontSendSms = this.item.isDontSendSms;
            this.isPartnerRepliedToBDayEmail = this.item.isPartnerRepliedToBDayEmail;
            if (this.item.genderId === 26) {
                this.gender = 'Male';
            } else if (this.item.genderId === 27) {
                this.gender = 'Female';
            } else {
                this.gender = 'All';
            }
            this.defaultPhoneArray = UtilsHelper.deepClone(this.item.phone);
            this.defaultEmailArray = UtilsHelper.deepClone(this.item.email);
        },
            () => { });
    }

    public changeStatus(propertyId: PersonPropertiesToUpdate) {
        switch (propertyId) {
            case PersonPropertiesToUpdate.CompanyAddress:
                this.dialog.open(CustomAddressConfirmComponent, {
                    disableClose: true,
                    maxWidth: "100vw",
                    data: {
                        closeModal: this.closeDateMeetingModal.bind(this),
                        saveDate: this.confirmAddressModal.bind(this),
                        dealsPerson: this.dealsPerson,
                        initPersonDataType: ConfirmPersonDataType.Address
                    }
                });
                break;
            case PersonPropertiesToUpdate.HomeAddress:
                this.dialog.open(CustomAddressConfirmComponent, {
                    disableClose: true,
                    maxWidth: "100vw",
                    data: {
                        closeModal: this.closeDateMeetingModal.bind(this),
                        saveDate: this.confirmAddressModal.bind(this),
                        dealsPerson: this.dealsPerson,
                        initPersonDataType: ConfirmPersonDataType.Address
                    }
                });
                break;
            case PersonPropertiesToUpdate.PersonWhoReferred: this.addPersonWhoReferredValue = !this.addPersonWhoReferredValue;
                break;
            case PersonPropertiesToUpdate.Company:
                this.dialog.open(CustomAddressConfirmComponent, {
                    disableClose: true,
                    maxWidth: "100vw",
                    data: {
                        closeModal: this.closeDateMeetingModal.bind(this),
                        saveDate: this.confirmAddressModal.bind(this),
                        dealsPerson: this.dealsPerson,
                        initPersonDataType: ConfirmPersonDataType.Company
                    }
                });
                break;
            case PersonPropertiesToUpdate.Location:
                this.dialog.open(CustomAddressConfirmComponent, {
                    disableClose: true,
                    maxWidth: "100vw",
                    data: {
                        closeModal: this.closeDateMeetingModal.bind(this),
                        saveDate: this.confirmAddressModal.bind(this),
                        dealsPerson: this.dealsPerson,
                        initPersonDataType: ConfirmPersonDataType.Location
                    }
                });
                break
            case PersonPropertiesToUpdate.Position:
                this.item.position = this.dealsPerson.position
                if (this.positionMode === ValueMode.Edit || this.positionMode === ValueMode.Add) {
                    this.changePositionMode(ValueMode.Label);
                }
                else {
                    this.changePositionMode(ValueMode.Edit);
                }

                break;
            case PersonPropertiesToUpdate.LinkedinURL: this.addlinkedinUrlValue = !this.addlinkedinUrlValue;
                break;
            case PersonPropertiesToUpdate.Phone: this.addPhoneValue = !this.addPhoneValue;
                break;
            case PersonPropertiesToUpdate.Email: this.addEmailValue = !this.addEmailValue;
                break;
            case PersonPropertiesToUpdate.FirstName: this.addFirstNameValue = !this.addFirstNameValue;
                break;
            case PersonPropertiesToUpdate.LastName: this.addLastNameValue = !this.addLastNameValue;
                break;
            case PersonPropertiesToUpdate.PartnerFirstName: this.addPartnerFirstNameValue = !this.addPartnerFirstNameValue;
                break;
            case PersonPropertiesToUpdate.PartnerLastName: this.addPartnerLastNameValue = !this.addPartnerLastNameValue;
                break;
            case PersonPropertiesToUpdate.PartnerEmail: this.addPartnerEmailValue = !this.addPartnerEmailValue;
                break;
            case PersonPropertiesToUpdate.PartnerPhone: this.addPartnerPhoneValue = !this.addPartnerPhoneValue;
                break;
        }
    }

    private addEmptyPhone() {
        let emptyPhone = new Phone();
        emptyPhone.label = 'Work';
        this.item.phone.push(emptyPhone);
    }

    private addEmptyEmail() {
        let emptyEmail = new Email();
        emptyEmail.label = 'Work';
        this.item.email.push(emptyEmail);
    }

    private addEmptyPartnerPhone() {
        let emptyPhone = new Phone();
        emptyPhone.value = '';
        this.partnerPhoneArray.push(emptyPhone);
    }

    public phonesCancel() {
        this.item.phone = UtilsHelper.deepClone(this.defaultPhoneArray);
        this.changeStatus(PersonPropertiesToUpdate.Phone);
    }

    public emailsCancel() {
        this.item.email = UtilsHelper.deepClone(this.defaultEmailArray);
        this.changeStatus(PersonPropertiesToUpdate.Email);
    }

    public partnerPhonesCancel() {
        this.partnerPhoneArray = UtilsHelper.deepClone(this.defaultPartnerPhoneArray);
        this.changeStatus(PersonPropertiesToUpdate.PartnerPhone);
    }

    /**
    * Routing
    * navigate to pipelines table
    * @param {any} person selected person
    * @memberof DealsDetailsComponent
    */

    public routing(person: Deal) {
        let previousPath = this.previousRouteService.getPreviousUrl()
        let currentPath = this.previousRouteService.getCurrentUrl();
        if (previousPath && currentPath !== previousPath) {
            this.router.navigate([previousPath]);
        } else {
            this.router.navigate(["/pipeline", person.pipeline_id]);
        }
    }

    public openStageSelect() {
        this.previousStageId = this.dealsPerson.stage_id;
    }

    public async updateStageConfirmation(stageId) {
        const noteService = this.noteService;

        this.dealsPerson.homeAddress = this.item.homeAddress;
        this.dealsPerson.companyAddress = this.item.companyAddress;

        this.setOwnersByStage(stageId);

        switch (this.dealsPerson.stage_id) {
            case this.ALL_STAGES.Kill:
                if (this.currentUser.is_admin) {
                    this.killRecordModal.openModal((function () {
                        noteService.AddReasonOfKill(this.dealsPerson.id, this.killRecordModal.inputData).then(() => {
                            this.killRecordModal.inputData = "";
                            this.updateStage(stageId);
                        });
                    }).bind(this));
                }
                else {
                    this.killRecordModal.openModal((function () {
                        this.dealsPerson.stage_id = this.previousStageId;
                    }).bind(this));
                }

                break;
            case this.ALL_STAGES.Referral:
                this.referralModal.openReferralModal(this.dealsPerson, (function () {
                    this.updateStage(stageId);
                }).bind(this));
                break;
            case this.ALL_STAGES.Meeting:
                if (this.currentUser.role_id === RolesEnum.BB) {
                    if (this.item.phone.some(phone => phone.value !== "")) {
                        this.openCustomMeetingModal(this.dealsPerson, this.dealsPerson.meetingDateUtc, this.dealsPerson.stage_id);
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomMeetingModal(this.dealsPerson, this.dealsPerson.meetingDateUtc, this.dealsPerson.stage_id), true, false)
                    }
                }
                else {
                    if (this.item.email.some(email => email.value !== "")) {
                        this.openCustomMeetingModal(this.dealsPerson, this.dealsPerson.meetingDateUtc, this.dealsPerson.stage_id);
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomMeetingModal(this.dealsPerson, this.dealsPerson.meetingDateUtc, this.dealsPerson.stage_id), false, true)
                    }
                }
                break;
            case this.ALL_STAGES.Confirmed:
                if (this.currentUser.role_id === RolesEnum.BB) {
                    if (this.item.phone.some(phone => phone.value !== "")) {
                        this.openCustomMeetingConfirmModal(this.dealsPerson, this.dealsPerson.meetingDateUtc);
                        break;
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomMeetingConfirmModal(this.dealsPerson, this.dealsPerson.meetingDateUtc), true, false)
                        break;
                    }
                }
                else {
                    if (this.item.email.some(email => email.value !== "")) {
                        this.openCustomMeetingConfirmModal(this.dealsPerson, this.dealsPerson.meetingDateUtc)
                        break;
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomMeetingConfirmModal(this.dealsPerson, this.dealsPerson.meetingDateUtc), false, true)
                        break;
                    }
                }
                break;
            case this.ALL_STAGES.SM1:
                this.dialog.open(CustomMeetingComponent, {
                    disableClose: true,
                    maxWidth: "90vw",
                    data: {
                        moveLeadsToUser: this.moveLeadsToUser.bind(this),
                        closeModal: this.closeDateMeetingModal.bind(this),
                        saveDate: this.hideMeetingModal.bind(this),
                        dealsPerson: this.dealsPerson,
                        meetingDate: this.dealsPerson.meetingDateUtc,
                        stageId: this.dealsPerson.stage_id,
                    }
                });
                break;
            case this.ALL_STAGES.CurrentMeeting:
            case this.ALL_STAGES.EACurrentMeeting:
            case this.ALL_STAGES.MeetingSet:
                if (this.currentUser.role_id === RolesEnum.BB) {
                    if (this.item.phone.some(phone => phone.value !== "")) {
                        this.openCustomAddressConfirmModal(this.dealsPerson, this.meetingDateModal)
                        break;
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomAddressConfirmModal(this.dealsPerson, this.meetingDateModal), true, false);
                        break;
                    }
                }
                else {
                    if (this.item.email.some(email => email.value !== "")) {
                        this.openCustomAddressConfirmModal(this.dealsPerson, this.meetingDateModal)
                        break;
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomAddressConfirmModal(this.dealsPerson, this.meetingDateModal), false, true);
                    }
                }
                break;
            case this.ALL_STAGES.DeliveryMeeting:
                if (this.currentUser.role_id === RolesEnum.BB) {
                    if (this.item.phone.some(phone => phone.value !== "")) {
                        this.openCustomAddressConfirmMaxDateModal(this.dealsPerson, this.meetingDateModal)
                        break;
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomAddressConfirmMaxDateModal(this.dealsPerson, this.meetingDateModal), true, false);
                        break;
                    }
                }
                else {
                    if (this.item.email.some(email => email.value !== "")) {
                        this.openCustomAddressConfirmMaxDateModal(this.dealsPerson, this.meetingDateModal);
                        break;
                    }
                    else {
                        this.openRequiredCustomContactsModal(this.dealsPerson, () => this.openCustomAddressConfirmMaxDateModal(this.dealsPerson, this.meetingDateModal), false, true);
                        break;
                    }
                }
                break;
            case this.ALL_STAGES.TryNextSeason:
                this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.TryNextSeasonOptions);
                break;
            case this.ALL_STAGES.Reschedule:
                this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.RescheduleOptions);
                break;
            case this.ALL_STAGES.InAlterations:
                this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.InAlterationsOptions);
                break;
            case this.ALL_STAGES.NG:
                this.dealsPerson.personWhoReferred = "";
                this.dealsPerson.personWhoReferredId = null;
                this.updateStage(stageId);
                break;
            case this.ALL_STAGES.ClientSaved:
                let main = this;
                this.dialog.open(CustomAddressConfirmComponent, {
                    disableClose: true,
                    maxWidth: "100vw",
                    data: {
                        closeModal: this.closeDateMeetingModal.bind(this),
                        saveDate: this.confirmAddressModal.bind(this),
                        dealsPerson: this.dealsPerson,
                        confirmCallback: function () { main.updateStage(stageId); },
                    }
                });
                break;
            case this.ALL_STAGES.LookForClientContactInfo:
                this.dealService.checkExtraDealMoveRules(this.dealsPerson.id, this.dealsPerson.stage_id).then((response) => {
                    if (!response.status) {
                        alert(response.message)
                        this.resetStageToPrevious();
                        return;
                    }
                    this.updateStage(stageId);
                });
                break;
            default:
                this.updateStage(stageId);
                break;
        }
    }

    setOwnersByStage(stageId: number) {
        let allowedRoles = [];
        if (this.pipelineId === stages.Pipelines.NewProspects || this.pipelineId === stages.Pipelines.ClothierContactClients || this.pipelineId === stages.Pipelines.ClothierMeetingConfirm) {
            const stageName = stages.STAGES_FOR_ALL_PIPELINES.filter(stage => stage.id === stageId)[0].name;
            if (stageId !== this.ALL_STAGES.LinkedinLOOKUP && stageId !== this.ALL_STAGES.ColdLOOKUP) {
                allowedRoles = Object.entries(stages.STAGES_BY_ROLES_VISIBILITY).reduce((result, current) => {
                    if (current[1].some(stage => stage === stageName)) {
                        result.push(RolesEnum[current[0]]);
                    }
                    return result;
                }, []);
            } else {
                allowedRoles.push(RolesEnum.MasterLead);
            }
            this.filteredUsers = this.users.filter(user => allowedRoles.some(roleId => roleId === user.role_id) && user.role_id !== RolesEnum.EA && user.role_id !== RolesEnum.EaCaller);
            this.clothierMeetingConfirmFilter();
        }
    }

    public updateStage(stageId) {
        this.spinner = true;
        this.dealsPerson.stage_id = stageId;
        this.isShowStageMessage = this.dealsPerson.stage_id === this.ALL_STAGES.Meeting;
        this.dealsPerson.previous_labeled_stage_id = this.getPreviousLabeledStageId(this.dealsPerson);
        this.dealService.updateStage(this.dealsPerson.id, this.dealsPerson.stage_id, this.dealsPerson.previous_labeled_stage_id, this.previousStageId, false,
            null, null, null, null).then(async (result) => {
                await this.refreshDataForStore();
                this.dealsPerson.stage_id = result.stage_id;
                this.disableChangeOwner = this.dealsPerson.stage_id === this.ALL_STAGES.Meeting || this.dealsPerson.stage_id === this.ALL_STAGES.CurrentMeeting
                    || this.dealsPerson.stage_id === this.ALL_STAGES.ClientSaved || this.pipelineId === stages.ClientsPipeline.id || this.pipelineId === stages.OpenOrders.id || this.pipelineId === stages.ClothierContactClientsPipeline.id;
                if (this.currentUser.role_id === RolesEnum.Clothier && (this.dealsPerson.stage_id === this.ALL_STAGES.Referral || this.dealsPerson.stage_id === this.ALL_STAGES.RefVM1 || this.dealsPerson.stage_id === this.ALL_STAGES.RefVM2 || this.dealsPerson.stage_id === this.ALL_STAGES.RefVM3 || this.dealsPerson.stage_id === this.ALL_STAGES.Float)) {
                    this.disableChangeOwner = true;
                }
                if (this.dealsPerson.stage_id === this.ALL_STAGES.NewOrder && this.dealsPerson.dateOfCall) {
                    this.dealsPerson.dateOfCall = null;
                    this.dealsPerson.called = false;
                }

                this.previousStageId = this.dealsPerson.stage_id;
                this.spinner = false;
            }, async (error) => {
                await this.reloadDealAfterError(error);
            });
    }

    public onTrackingLinkCanceled() {
        if (this.isOrderShippedChanging) {
            this.dealsPerson.isOrderShipped = false;
            this.isOrderShippedChanging = false;
        }
        else {
            this.resetStageToPrevious();
        }
    }

    public async onTrackingLinkConfirmed() {
        if (this.isOrderShippedChanging) {
            await this.dealService.updateTrackingLink({ dealId: this.dealsPerson.id, trackingLink: this.trackingLinkModal.inputData });
            await this.dealService.updateIsOrderShipped(this.dealsPerson.id, true);

            if (
                this.dealsPerson.stage_id === this.ALL_STAGES.Less3Weeks
                || this.dealsPerson.stage_id === this.ALL_STAGES.Less6Weeks
                || this.dealsPerson.stage_id === this.ALL_STAGES.Less9Weeks
                || this.dealsPerson.stage_id === this.ALL_STAGES.OverdueLess12Weeks
            ) {
                this.updateStage(this.ALL_STAGES.OrderShipped);
            }

            this.isOrderShippedChanging = false;
        }
        else {
            await this.dealService.updateTrackingLink({ dealId: this.dealsPerson.id, trackingLink: this.trackingLinkModal.inputData });
            this.trackingLinkModal.inputData = "";
            this.updateStage(this.dealsPerson.stage_id);
        }
    }

    private getPreviousLabeledStageId(item: any): number | null {
        let prospectStagesFrom = [this.ALL_STAGES.DM, this.ALL_STAGES.SM, this.ALL_STAGES.Float, this.ALL_STAGES.SM1]
        let prospectStagesTo = [this.ALL_STAGES.VM1, this.ALL_STAGES.VM2, this.ALL_STAGES.VM3];
        let clientStagesFrom = [this.ALL_STAGES.ContactClientNow]
        let clientStagesTo = [this.ALL_STAGES.EAVMEmail1, this.ALL_STAGES.EAVMEmail2];
        if (!prospectStagesTo.some(s => s === item.stage_id) && !clientStagesTo.some(s => s === item.stage_id)) {
            return null;
        }
        if ((prospectStagesFrom.some(s => s === this.previousStageId) && prospectStagesTo.some(s => s === item.stage_id)) || (clientStagesFrom.some(s => s === this.previousStageId) && clientStagesTo.some(s => s === item.stage_id))) {
            return this.previousStageId;
        }
        else {
            return item.previous_labeled_stage_id;
        }
    }

    public getLabeledStageName(stageId: number): string {
        const previousLabeledStage = stages.STAGES_FOR_ALL_PIPELINES.find(s => s.id === stageId);
        return (previousLabeledStage) ? previousLabeledStage.name : "";
    }

    private async reloadDealAfterError(error: any): Promise<void> {
        if (error.status === 403) {
            await this.getDealById();
        }
        if (error.status === 409) {
            alert(this.dealService.UpdateStageConflictMessage);
            await this.getDealById();
        }

        this.spinner = false;
    }

    private resetStageToPrevious() {
        this.dealsPerson.stage_id = this.previousStageId;
    }

    public cancelMoveRecord() {
        this.resetStageToPrevious();
    }

    public closeDateMeetingModal() {
        this.resetStageToPrevious();
    }

    public closeCustomDateModal() {
        this.resetStageToPrevious();
    }

    public saveDateOfMeeting(date) {
        if (date) {
            this.spinner = true;
            let meeting_date = DateHelper.formatAsISODateString(date);
            let meeting_date_utc = date;
            let meeting_time = date.toLocaleTimeString([], { hour12: false });
            this.dealService.updateStage(this.dealsPerson.id, this.dealsPerson.stage_id, null, this.previousStageId, false,
                meeting_date, meeting_date_utc, meeting_time, null).then((res) => {
                    this.disableChangeOwner = true;
                    this.previousStageId = this.dealsPerson.stage_id;
                    this.spinner = false;
                }, async (error) => {
                    await this.reloadDealAfterError(error);
                });
        }
    }

    public saveCustomDate(event) {
        if (event) {
            this.spinner = true;
            this.dealService.updateStage(this.dealsPerson.id, this.dealsPerson.stage_id, null, this.previousStageId, false, null, null, null
                , null, event.date, event.isNrContactedRepeat).then((res) => {
                    this.previousStageId = this.dealsPerson.stage_id;
                    this.spinner = false;
                }, async (error) => {
                    await this.reloadDealAfterError(error);
                });
        }
    }

    /**
    * Update owner
    * in deal
    * @memberof DealsDetailsComponent
    */

    public updateOwner() {
        this.updateDealOwner(this.dealsPerson.id, this.dealsPerson.user_id);
    }

    public async onMoveThisLeadToMe() {
        await this.moveThisLeadToMe(this.dealsPerson.id, this.currentUser.id);
    }

    updateIsMyDeal(): any {
        this.isMyDealOrMySubordinate = this.isMyDeal || this.isMySubordinate;
    }

    private get isMySubordinate(): boolean {
        return this.currentUser.isMarketLeader && this.currentUser.leaderOf?.findIndex(x => x === this.dealsPerson.user_id) !== -1;
    }

    private get isMyDeal(): boolean {
        return this.dealsPerson && this.currentUser.id === this.dealsPerson.user_id;;
    }

    /**
    * Check is not use
    * this name as a connections
    * @param {any} event change checkbox
    * @memberof DealsDetailsComponent
    */

    public checkIsNotUse() {
        this.isNotUseAsCommonConnection = !this.isNotUseAsCommonConnection;
        this.dealService.updateUsingCommonConnection(this.dealsPerson.personId, this.dealsPerson.id, this.isNotUseAsCommonConnection);
    }

    public checkIsDontSendPromotionalEmails() {
        this.isDontSendPromotionalEmails = !this.isDontSendPromotionalEmails;
        this.dealService.updateSendPromotionalEmails(this.dealsPerson.personId, this.dealsPerson.id, this.isDontSendPromotionalEmails);
    }

    public checkIsDontSendPartnerEmails() {
        this.isDontSendPartnerEmails = !this.isDontSendPartnerEmails;
        this.dealService.updateSendPartnerEmails(this.dealsPerson.personId, this.dealsPerson.id, this.isDontSendPartnerEmails);
    }

    public checkIsDontSendWhatsapp() {
        this.isDontSendWhatsapp = !this.isDontSendWhatsapp;
        this.dealService.updateSendWhatsapp(this.dealsPerson.personId, this.dealsPerson.id, this.isDontSendWhatsapp);
    }

    public checkIsDontSendSms() {
        this.isDontSendSms = !this.isDontSendSms;
        this.dealService.updateSendSms(this.dealsPerson.personId, this.dealsPerson.id, this.isDontSendSms);
    }

    public checkIsPartnerRepliedToBDayEmail() {
        this.isPartnerRepliedToBDayEmail = !this.isPartnerRepliedToBDayEmail;
        this.dealService.updateIsPartnerRepliedToBDayEmail(this.dealsPerson.personId, this.dealsPerson.id, this.isPartnerRepliedToBDayEmail);
    }

    public changeCalled() {
        this.dealsPerson.called = !this.dealsPerson.called;
        this.dealsPerson.dateOfCall = new Date();
        this.dealService.updateCalled(this.dealsPerson.id, this.dealsPerson.called);
    }

    public changePreferToContactByEmail() {
        this.dealsPerson.preferToContactByEmail = !this.dealsPerson.preferToContactByEmail;
        this.dealService.updatePreferToContactByEmail(this.dealsPerson.id, this.dealsPerson.preferToContactByEmail);
    }

    public changeIsDeliveryEmailSent() {
        const main = this;
        this.eaFollowUpImageUpload.openUploadModal(
            () => {
                main.dealsPerson.isDeliveryEmailSent = true;
                main.dealService.updateIsDeliveryEmailSent(main.dealsPerson.id, main.dealsPerson.isDeliveryEmailSent).then((result) => {
                    if (result) {
                        main.dealsPerson.isRotting = false;
                        main.eaFollowUpTable.loadImages();
                    }
                });
            }
            , () => { main.dealsPerson.isDeliveryEmailSent = false }
            , this.dealsPerson.id, this.dealsPerson.stage_id);
    }

    public deleteDeal(dealId) {
        this.dealService.deleteDeal(dealId).then(result => {
            alert("Deal was deleted, thank you!");
            this.router.navigate(['/GlobalSearch']);
        });

    }

    public updateGender(id, gender) {
        this.dealService.updateInformation(id, PersonPropertiesToUpdate.Gender, gender, this.dealsPerson.id);
    }

    public async getDealHistory(): Promise<void> {
        if (this.currentUser.role_id === RolesEnum.MasterLead
            || this.currentUser.role_id === RolesEnum.SystemAccount
            || (this.currentUser.role_id === RolesEnum.Admin && this.currentUser.isAllowedHistoryOfLeads)) {
            this.dealHistory = await this.dealService.getDealHistory(this.dealsPerson.id);
        }
    }

    public getTypeOfFollowUp(stageId: number): string {
        return DealUtil.getEaFollowUpTypeLabel(stageId);
    }

    public saveWhoReferred(whoReferredText) {
        this.dealService.updatePersonWhoReferred(this.dealsPerson.id, whoReferredText);
        this.dealsPerson.personWhoReferred = whoReferredText;
    }

    public closeReferralModal() {
        this.resetStageToPrevious();
    }

    public hideMeetingModal(model) {
        const dealsPerson = model.dealsPerson as DealDetails;
        const selectedUser = model.selectedUser as User;
        let meetingDate = dealsPerson.meetingDateUtc;
        let isDashboardView = false;
        this.spinner = true;

        this.dealService.updatePersonAddressesAndGeocode(
            dealsPerson.personId,
            dealsPerson.companyAddress,
            dealsPerson.homeAddress,
            dealsPerson.company,
            dealsPerson.location,
            dealsPerson.latitude,
            dealsPerson.longitude)
            .then((result) => {
                if (meetingDate) {
                    let meeting_date = DateHelper.formatAsISODateString(meetingDate);
                    let meeting_time = meetingDate.toLocaleTimeString([], { hour12: false });
                    let meeting_date_utc = meetingDate;
                    this.dealService.updateStage(this.dealsPerson.id, this.dealsPerson.stage_id, null, this.previousStageId,
                        isDashboardView, meeting_date, meeting_date_utc, meeting_time, (selectedUser) ? selectedUser.id : null).then(() => {
                            this.dealsPerson.homeAddress = dealsPerson.homeAddress;
                            this.dealsPerson.companyAddress = dealsPerson.companyAddress;
                            this.dealsPerson.company = dealsPerson.company;
                            this.dealsPerson.location = dealsPerson.location;

                            this.item.homeAddress = this.dealsPerson.homeAddress;
                            this.item.companyAddress = this.dealsPerson.companyAddress;
                            this.item.company = this.dealsPerson.company;
                            this.item.location = this.dealsPerson.location;

                            this.updateTineZone();
                            this.updateStagesAndPipelines();
                        }, async (error) => {
                            await this.reloadDealAfterError(error);
                        });
                } else {
                    this.dealService.updateStage(this.dealsPerson.id, this.dealsPerson.stage_id, this.dealsPerson.previous_labeled_stage_id, this.previousStageId, false,
                        null, null, null, null).then(async (res) => {
                            await this.getDealById();
                        }, async (error) => {
                            await this.reloadDealAfterError(error);
                        });
                }
                this.spinner = false;
            }, async (error) => {
                await this.reloadDealAfterError(error);
            });
    }

    private updateStagesAndPipelines(): void {
        const filteredStages = this.getAvailiableStageOptionsForDeal(this.stageFeatureStoreService.allStage.value,
            this.currentDeal, this.currentUser, this.userFilter);
        this.pipelines = this.stageService.stagesToPipelinesArray(filteredStages,
            this.currentDeal && this.currentDeal.stage_id || null
        );
    }

    public confirmAddressModal(dealsPerson: DealDetails) {
        this.spinner = true;
        this.dealService.updatePersonAddressesAndGeocode(
            dealsPerson.personId,
            dealsPerson.companyAddress,
            dealsPerson.homeAddress,
            dealsPerson.company,
            dealsPerson.location,
            dealsPerson.latitude,
            dealsPerson.longitude)
            .then((result) => {
                this.dealsPerson.homeAddress = dealsPerson.homeAddress;
                this.dealsPerson.companyAddress = dealsPerson.companyAddress;
                this.dealsPerson.company = dealsPerson.company;
                this.dealsPerson.location = dealsPerson.location;

                this.item.homeAddress = this.dealsPerson.homeAddress;
                this.item.companyAddress = this.dealsPerson.companyAddress;
                this.item.company = this.dealsPerson.company;
                this.item.location = this.dealsPerson.location;

                this.updateTineZone();

                this.spinner = false;
            }, async (error) => {
                await this.reloadDealAfterError(error);
            });
    }

    public onSwichIframeStyle() {
        var getHeaderFrame = document.querySelector('.frame-header');
        getHeaderFrame.classList.add('height-zero');

        var getFrame = document.querySelector('#getFrame');
        getFrame.classList.add("add-get-frame");

        var cardBody = document.querySelector('.custom-card-body');
        cardBody.classList.add('display-none');
    }

    public async onSwichDivStyle(): Promise<void> {
        await this.getDealById();
        var getHeaderFrame = document.querySelector('.frame-header');
        getHeaderFrame.classList.remove('height-zero');

        var getFrame = document.querySelector('#getFrame');
        getFrame.classList.remove("add-get-frame");

        var cardBody = document.querySelector('.custom-card-body');
        cardBody.classList.remove('display-none');
    }

    public moveLeadsToUser(model) {
        let selectedUser = model.selectedUser;
        let dealsPerson = model.dealsPerson as DealDetails;
        let meetingDate = dealsPerson.meetingDateUtc;
        this.spinner = true;
        this.dealService.updatePersonAddressesAndGeocode(
            dealsPerson.personId,
            dealsPerson.companyAddress,
            dealsPerson.homeAddress,
            dealsPerson.company,
            dealsPerson.location,
            dealsPerson.latitude,
            dealsPerson.longitude)
            .then(async () => {
                this.spinner = true;
                if (this.dealsPerson.stage_id != this.ALL_STAGES.SM1) {
                    if (this.dealsPerson.user_id !== selectedUser.id)
                        this.bookedBy = this.currentUser.name
                    let isDashboardView = false;
                    let meeting_date = DateHelper.formatAsISODateString(meetingDate);
                    let meeting_date_utc = meetingDate;
                    let meeting_time = meetingDate.toLocaleTimeString([], { hour12: false });

                    await this.dealService.moveToMeetingStage(this.dealsPerson.id, this.dealsPerson.stage_id, this.previousStageId,
                        selectedUser.id, isDashboardView, meeting_date, meeting_date_utc, meeting_time);
                    await this.getDealById();
                } else {
                    this.dealService.updateStage(this.dealsPerson.id, this.dealsPerson.stage_id, null, this.previousStageId, false,
                        null, null, null, selectedUser.id).then(async () => {
                            await this.getDealById();
                        }, async (error) => {
                            await this.reloadDealAfterError(error);
                        });
                }

                this.spinner = false;
            }, async (error) => {
                await this.reloadDealAfterError(error);
            });
    }

    public updateClientGroup(group: number) {
        this.dealService.updateClientGroup(this.id, group);
    }

    public getPreviousLabeledStageDescription(): string {
        return (this.pipelineId === stages.ClientsPipeline.id || this.pipelineId === stages.OpenOrders.id) ? "Previous stage before VM/Email" : "Previous stage before VM";
    }

    public onRottingIncreasingChanged(isChecked: boolean) {
        if (isChecked) {
            this.isIncreasedRottingModal.openModal(null);
        } else {
            if (this.currentDeal && this.currentDeal.id) {
                this.currentDeal.isIncreasedRecentlyContactedRotting = isChecked;
                this.dealsPerson.isIncreasedRecentlyContactedRotting = isChecked;
                this.dealService.updateIsIncreasedRecentlyContactedRotting({ dealId: this.currentDeal.id, isIncreasedRecentlyContactedRotting: isChecked });
            }
        }
    }

    public onIsForClothierChanged(isChecked: boolean) {
        if (isChecked) {
            this.isForClothierModal.openModal(null);
        } else {
            this.isForClothierCancelModal.openModal(null);
        }
    }

    public onIsOrderShippedChanged() {
        this.isOrderShippedChanging = true;
        this.trackingLinkModal.openModal(null);
    }

    public changeIsOpenOrderNotified() {
        const main = this;
        this.eaFollowUpImageUpload.openUploadModal(
            () => {
                main.dealsPerson.isOpenOrderNotified = true;
                main.dealService.updateIsOpenOrderNotified(main.dealsPerson.id, main.dealsPerson.isOpenOrderNotified).then((result) => {
                    if (result) {
                        main.dealsPerson.isRotting = false;
                        main.eaFollowUpTable.loadImages();
                    }
                });
            }
            , () => { main.dealsPerson.isOpenOrderNotified = false }
            , this.dealsPerson.id, this.dealsPerson.stage_id);
    }

    public updateEaFollowUpImage(imageId: number) {
        const main = this;
        this.eaFollowUpImageUpload.openUploadModal(() => { main.eaFollowUpTable.loadImages(); }, null, this.dealsPerson.id, this.dealsPerson.stage_id, imageId);
    }

    public onRottingIncreasingConfirmed() {
        if (this.currentDeal && this.currentDeal.id) {
            this.currentDeal.isIncreasedRecentlyContactedRotting = true;
            this.dealsPerson.isIncreasedRecentlyContactedRotting = true;
            this.dealService.updateIsIncreasedRecentlyContactedRotting({ dealId: this.currentDeal.id, isIncreasedRecentlyContactedRotting: true });
        }
    }

    public onIsForClothierConfirmed() {
        if (this.currentDeal && this.currentDeal.id) {
            this.currentDeal.isForClothier = true;
            this.dealsPerson.isForClothier = true;
            this.dealService.updateIsForClothier({ dealId: this.currentDeal.id, isForClothier: true });
        }
    }

    public onIsForClothierCanceled() {
        if (this.currentDeal && this.currentDeal.id) {
            this.currentDeal.isForClothier = false;
            this.dealsPerson.isForClothier = false;
            this.dealService.updateIsForClothier({ dealId: this.currentDeal.id, isForClothier: false });
        }
    }

    public selectBirthdayDate(id, value) {
        const d = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());
        const date = new Date(d);
        this.dealService.updateInformation(id, PersonPropertiesToUpdate.Birthday, date, this.dealsPerson.id);
    }

    public isRecruiterStage(): boolean {
        const stage = this.stages.STAGES_FOR_ALL_PIPELINES.find((st: any) => st.id === this.dealsPerson.stage_id);
        return this.stages.PROSPECTS_BY_ROLES["Recruiter"].some((st: any) => st === stage?.name);
    }

    public getToday() {
        return new Date()
    }

    public increaseRottingTime() {
        if (!this.dealsPerson.increasingRottenTimeCounter || this.dealsPerson.increasingRottenTimeCounter <= 2) {
            this.dealService.increaseRottingTime(this.dealsPerson.id).then(result => {
                if (result) {
                    this.dealsPerson.increasingRottenTimeCounter = this.dealsPerson.increasingRottenTimeCounter ?
                        this.dealsPerson.increasingRottenTimeCounter + 1 : 1;
                    this.dealsPerson.isRotting = false;
                }
            });
        }
    }

    public closeCustomContactsModal() {
        this.resetStageToPrevious()
    }

    public reselectRottingTimeReschedule() {
        this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.RescheduleOptions);
    }

    public reselectRottingTimeInAlteration() {
        this.rottenTimeModal.openCustomDateModal(null, rottenTimeModalOptions.InAlterationsOptions);
    }

    public reselectRottingTimeDeliveryMeeting() {
        let meetingDateModal = this.meetingDateModal;
        let maxDate = new Date(new Date().setDate((new Date()).getDate() + 5 * 7));
        this.dialog.open(CustomAddressConfirmComponent, {
            disableClose: true,
            maxWidth: "100vw",
            data: {
                closeModal: this.closeDateMeetingModal.bind(this),
                saveDate: this.confirmAddressModal.bind(this),
                dealsPerson: this.dealsPerson,
                confirmCallback: function () { meetingDateModal.openMeetingDateModal(null, maxDate); },
            }
        });
    }

    public closeMeetingModal() {
        this.resetStageToPrevious()
    }

    public get isClient(): boolean {
        if (!this.dealsPerson) {
            return false;
        }

        let stage_id = this.dealsPerson.stage_id;
        let pipeline_id = this.dealsPerson.pipeline_id;
        return stage_id === this.ALL_STAGES.DraftOrder
            || stage_id === this.ALL_STAGES.DraftConfirmed
            || pipeline_id === stages.Pipelines.Clients
            || pipeline_id === stages.Pipelines.OpenOrders
            || pipeline_id === stages.Pipelines.ClothierContactClients
    }

    private openRequiredCustomContactsModal(dealDetails: any, confirmCallback: () => void, isPhoneRequired: boolean, isEmailRequired: boolean): void {
        const deal = new Deal();
        deal.personId = dealDetails.personId;
        deal.id = dealDetails.id;
        deal.phone = dealDetails.phone;
        deal.email = dealDetails.email;
        const dialogRef = this.dialog.open(CustomContactsModalComponent, {
            disableClose: true,
            maxWidth: '90vw',
            data: {
                isPhoneRequired: isPhoneRequired,
                isEmailRequired: isEmailRequired,
                closeModal: this.closeCustomContactsModal.bind(this),
                deal: deal,
                isClient: this.isClient,
                confirmCallback: confirmCallback,
            }
        });

        const sub = dialogRef.componentInstance.saveData.subscribe((contactData: any) => {
            this.item.phone = contactData.phones;
            this.item.email = contactData.emails;
            this.defaultPhoneArray = UtilsHelper.deepClone(this.item.phone);
            this.defaultEmailArray = UtilsHelper.deepClone(this.item.email);
        });

        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }

    private openCustomAddressConfirmModal(dealsPerson: any, meetingDateModal: any): void {
        this.dialog.open(CustomAddressConfirmComponent, {
            disableClose: true,
            maxWidth: '100vw',
            data: {
                closeModal: this.closeDateMeetingModal.bind(this),
                saveDate: this.confirmAddressModal.bind(this),
                dealsPerson: dealsPerson,
                confirmCallback: () => meetingDateModal.openMeetingDateModal(null),
            }
        });
    }

    private openCustomMeetingModal(dealsPerson: any, meetingDate: Date, stageId: number): void {
        this.dialog.open(CustomMeetingComponent, {
            disableClose: true,
            maxWidth: "90vw",
            data: {
                moveLeadsToUser: this.moveLeadsToUser.bind(this),
                closeModal: this.closeDateMeetingModal.bind(this),
                saveDate: this.hideMeetingModal.bind(this),
                dealsPerson: dealsPerson,
                meetingDate: meetingDate,
                stageId: stageId,
            }
        });
    }

    public openCustomAddressConfirmMaxDateModal(dealsPerson: any, meetingDateModal: any): void {
        let maxDate = new Date(Date.now());
        maxDate.setMonth(maxDate.getMonth() + 2);

        this.dialog.open(CustomAddressConfirmComponent, {
            disableClose: true,
            maxWidth: "100vw",
            data: {
                closeModal: this.closeDateMeetingModal.bind(this),
                saveDate: this.confirmAddressModal.bind(this),
                dealsPerson: dealsPerson,
                confirmCallback: function () { meetingDateModal.openMeetingDateModal(null); },
            }
        });
    }

    public openCustomMeetingConfirmModal(dealsPerson: any, meetingDate: Date): void {
        this.dialog.open(CustomMeetingConfirmComponent, {
            disableClose: true,
            maxWidth: "90vw",
            data: {
                closeModal: this.closeDateMeetingModal.bind(this),
                saveDate: this.hideMeetingModal.bind(this),
                dealsPerson: dealsPerson,
                meetingDate: meetingDate,
            }
        });
    }

    public get isAiAgent(): boolean {
        const owner = this.users.find(u => u.id === this.dealsPerson.user_id);
        return owner && owner.isAiAgent;
    }

    public openCallDialog() {
        const phones = this.item.phone;
        const dealId = this.dealsPerson.id;

        this.dialog.open(CallModalComponent, {
            width: '400px',
            data: { dealId, phones }
        });
    }

    public async searchLinkedinUrl() {
        this.spinner = true;
        var result = await this.callService.searchLinkedinUrl(this.dealsPerson.id);
        this.spinner = false;
        if (result.success) { //TODO: add Linkedin reload
            await this.getDealById();
            this.notificationService.showSuccess(result.message);
        }
        else {
            this.notificationService.showError(result.message);
        }
    }

    public async searchPhoneNumbers() {
        this.spinner = true;
        var result = await this.callService.searchContacts(this.dealsPerson.id);
        this.spinner = false;
        if (result.success) { //TODO: add phones reload
            this.notificationService.showSuccess(result.message);
        }
        else {
            this.notificationService.showError(result.message);
        }

        if (result.reload) { //TODO: add phones reload
            await this.getDealById();
        }
    }

    public get isCandidate(): boolean {
        if (!this.dealsPerson) {
            return false;
        }

        let stage_id = this.dealsPerson.stage_id;
        return stage_id === this.ALL_STAGES.NewCandidates
            || stage_id === this.ALL_STAGES.ReachOut
            || stage_id === this.ALL_STAGES.SetInterview1
            || stage_id === this.ALL_STAGES.Interview1
            || stage_id === this.ALL_STAGES.Interview2
            || stage_id === this.ALL_STAGES.OfferSent
            || stage_id === this.ALL_STAGES.RecruiterNG
            || stage_id === this.ALL_STAGES.Hired
            || stage_id === this.ALL_STAGES.RecruiterNgWithoutVideo
            || stage_id === this.ALL_STAGES.VideoApplications
            || stage_id === this.ALL_STAGES.AdditionalQuestions
            || stage_id === this.ALL_STAGES.SetInterview2
            || stage_id === this.ALL_STAGES.AdditionalInterview
            || stage_id === this.ALL_STAGES.FollowDay
    }

    public openHotlist(id, title) {
        this.router.navigate(['/Hotlist', id, title]);
    }

    public openHotlistPdf(id) {
        this.hotlistService.getHotlistPdf(id).then((result) => {
            if (result)
                printJS({ printable: result, type: 'pdf', base64: true })
        })
    }

    public exportWardrobePlan(id, title) {
        this.wardrobePlan.printPdf(id, title);
    }

    public uploadWardrobePlanModal(deal) {
        this.uploadWardrobePlan.openUploadModal(deal);
    }

    public openMeetingPrepPdf(dealPerson) {
        var deal = new Deal();
        deal.id = dealPerson.id;
        deal.title = dealPerson.name;
        this.meetingPrep.openMeetingPrepPdf(deal);
    }

    public getDealLink(dealId: number) {
        return "/deals/" + dealId;
    }

    // public getPersonInfoUrl() { //TODO:PD-2386
    //     return "https://dev.lgfgfashionhouse.com/en/partners-information/?t=" + this.item.partnerToken;
    // }

    //////////////////////////////// Position select START //////////////////////////////

    @ViewChild("positionFilter") customSelectPosition: CustomSelectFilterComponent;
    public positionMode = ValueMode.Label
    public valueMode = ValueMode
    public filterType = FilterType
    public selectedPosition: string;

    public changePositionMode(newValue: ValueMode) {
        this.positionMode = newValue

        if (this.positionMode === ValueMode.Add) {
            this.selectedPosition = '';
        }
        else {
            this.selectedPosition = this.item.position;
        }
    }

    onPositionsChanged() {
        this.selectedPosition = this.customSelectPosition.localChecked[0].name
    }

    getPositionsFilterData(event) {
        const isNotleter: number = event.isNotLetter;
        this.dealService.getFilterData(FilterType.Position, event.skip, event.take).then(response => {
            if (this.customSelectPosition && isNotleter == this.customSelectPosition.isNotLetter) {
                this.customSelectPosition.setData(response);
            }
        });
    }

    getPositionsAutocomplete(event) {
        const isNotleter: number = event.isNotLetter;
        this.dealService.getFilterDataAutocomplete(FilterType.Position, event.searchString, event.skip, event.take).then(response => {
            if (this.customSelectPosition && isNotleter == this.customSelectPosition.isNotLetter) {
                this.customSelectPosition.setData(response);
            }
        });
    }

    //////////////////////////////// Position select END ////////////////////////////////

    private updateTineZone() {
        if (this.isNeedTimezone) {
            this.dealService.getDealLocalTimeZone(this.dealsPerson.id).then((timeZone) => {
                this.dealsPerson.timeZone = timeZone;
            });
        }
    }

    //////////////////////////////// VIEW disabled START //////////////////////////////

    public get isAllowToChangeOwnerOfSomeoneElseDeal() {
        return !this.isMyDeal &&
            (
                ((this.currentUser.role_id === RolesEnum.Admin || this.currentUser.role_id === RolesEnum.MasterLead) && this.isRecruiterStage())
                || ((this.currentUser.role_id === RolesEnum.Admin || this.currentUser.role_id === RolesEnum.Clothier) && (this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.ColdLOOKUP
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.LinkedinLOOKUP
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.Cold
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.Linkedin
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.SameBuilding
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.NG
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.DQ
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.NoAns
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.NoPhone
                    || this.dealsPerson.stage_id === GlobalConstants.ALL_STAGES.SM))
            );
    }

    public get isOwnerSelectDisabled(): boolean {
        return (!this.isAdmin && !this.isMyDealOrMySubordinate)
            || this.disableChangeOwner
            || (this.isClothierMeetingConfirmPipeline && !this.isFilterdRepoAccountAndCurrentUserMasterLead)
            || this.isNewOrderStage;
    }

    private get isClothierMeetingConfirmPipeline() {
        return this.pipelineId === this.STAGES_AND_PIPELINES.ClothierMeetingConfirmPipeline.id;
    }

    private get isNewOrderStage() {
        return this.dealsPerson.stage_id === this.ALL_STAGES.NewOrder;
    }

    private get isFilterdRepoAccountAndCurrentUserMasterLead() {
        return this.isFilterUserIsRepoAccount && this.currentUser.role_id == RolesEnum.MasterLead;
    }

    public get isNeedTimezone(): boolean {
        return this.isClient
            || this.dealsPerson.stage_id === this.ALL_STAGES.Meeting
            || this.dealsPerson.stage_id === this.ALL_STAGES.ClientSaved;
    }

    //////////////////////////////// VIEW disabled END //////////////////////////////

    public getGenderId(genderLabel: string) {
        if (genderLabel === 'Male') {
            return 26;
        } else if (genderLabel === 'Female') {
            return 27;
        }
        return null;
    }

    public getGenderLabel(genderId: number) {
        if (genderId === 26) {
            return 'Male';
        } else if (genderId === 27) {
            return 'Female';
        }
        return 'All';
    }

    //////////////////////////////// Private START //////////////////////////////

    private updateDealOwner(dealId: number, ownerId: number) {
        const array = [];
        array.push(dealId);
        this.dealService.updateOwnerDeals(array, ownerId).then(async () => {
            await this.refreshDataForStore();
            this.updateIsMyDeal();
        });
    }

    private async moveThisLeadToMe(dealId: number, ownerId: number) {
        await this.dealService.moveThisLeadToMe(dealId, ownerId);
        await this.refreshDataForStore();
        this.updateIsMyDeal();
    }

    //////////////////////////////// Private END //////////////////////////////
}
