<div class="row wrapper">
  <div class="col-3 title">
    <span style="color: red; font-size: 20px">*</span>Role
  </div>
  <div class="col-9 work-field">
    @if (!roleProperty.role && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if ((isEdit || roleProperty.role)) {
    <div class="edit-role">
      <div class="select">
        <mat-form-field class="owner-select-global">
          <mat-select [disabled]="disabled" (selectionChange)="changeValue($event.value)" [(value)]="roleProperty.role">
            @for (role of roleList; track role) {
            <div>
              <mat-option [disabled]="isRoleDisabled(role)" [value]="role">{{role.name}}</mat-option>
            </div>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (isAllowedFlags) {
      <div class="checkbox">
        <div class="row">

          @if (isAdmin) {
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isTasksTab">Tasks Tab</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isUsersTab">Users Tab</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isAllowedExportLeadDate">Export Lead
            Date</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isAllowedHistoryOfLeads">History Of
            Leads</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isRepoAccount">Repo Account</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isWhatsappChat">WhatsApp Chat</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isGlobalChat">Global Chat</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isAvatarEditor">Avatar Editor</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isSeeOthersCms">See other’s
            CMS</mat-checkbox>

          <div class="col-12">
            <mat-checkbox [(ngModel)]="roleProperty.isInSmReport">
              <span style="position: relative;">
                Include into SM report
                <div class="help-tip">
                  <p>In case you're providing Admin access to SM, please choose this option. This is needed to show the
                    results of these SMs in reports</p>
                </div>
              </span>
            </mat-checkbox>
          </div>
          }

          @if (isAdmin || isClothier) {
          <ng-container>
            <div class="col-12">
              <mat-checkbox [(ngModel)]="roleProperty.isNotInClothierReport">
                <span style="position: relative;">
                  Don't include into daily clothier progress report
                  <div class="help-tip">
                    <p>If this clothier/admin shouldn't be in the clothier progress daily report, please choose this
                      option</p>
                  </div>
                </span>
              </mat-checkbox>
            </div>
            @if(isClothier) {
            <div class="col-12">
              <mat-checkbox [(ngModel)]="roleProperty.isMarketLeader">
                <span style="position: relative;">
                  Is Market Leader
                </span>
              </mat-checkbox>
              @if(roleProperty.isMarketLeader) {
              <div style="padding-top: 10px;">
                <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                  (selectedItemEvent)="onSelectedItem($event)" (autoCompleteEvent)="getUsersAutocomplete($event)"
                  dataLabel="Leader of" startLabel="Leader of" [isMultiple]="true" [isNeedShowSelectAll]="false">
                </pd-custom-select-without-lazy>
              </div>
              }
            </div>
            }
          </ng-container>
          }

          @if(isBB) {
          <ng-container>
            <div class="col-12">
              <mat-checkbox [(ngModel)]="roleProperty.isSm">
                <span style="position: relative;">
                  Is SM
                </span>
              </mat-checkbox>
            </div>
            <div class="col-12">
              <mat-checkbox [(ngModel)]="roleProperty.isAiAgent">
                <span style="position: relative;">
                  Is Ai Agent
                </span>
              </mat-checkbox>
              @if(roleProperty.isAiAgent) {
              <div class="col-12" style="margin-top: 16px;">
                <div style="margin: 10px 0;font-weight: 600;">AI Agent Config:</div>
                <div class="ai-agent-config-row" *ngFor="let config of roleProperty.aiAgentConfigs; let i = index"
                  style="margin-bottom: 8px;">
                  <div class="row">
                    <div style="width: 120px;">
                      <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label>Agent Type</mat-label>
                        <mat-select [(ngModel)]="config.agentTypeId" [ngModelOptions]="{ standalone: true }">
                          <mat-option *ngFor="let st of agentTypeList" [value]="st.id"
                            [disabled]="isAgentTypeDisabled(st.id, i)">
                            {{ st.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div style="width: 180px;">
                      <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label>Agent</mat-label>
                        <mat-select [(ngModel)]="config.agentId" [ngModelOptions]="{ standalone: true }"
                          (selectionChange)="onAgentChanged(config)">
                          <mat-option [value]="''">Select agent</mat-option>
                          <mat-option *ngFor="let ag of availableAgents" [value]="ag.agentId">
                            {{ ag.agentName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div style="width: 175px;">
                      <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label>Phone</mat-label>
                        <mat-select [(ngModel)]="config.phone" [ngModelOptions]="{ standalone: true }">
                          <mat-option [value]="''">Select phone</mat-option>
                          <mat-option *ngFor="let vn of voiceNumbers" [value]="vn">
                            {{ vn }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-1" style="display: flex; align-items: center;">
                      <button mat-raised-button color="warn" type="button" (click)="removeAiAgentConfig(i)">
                        Del
                      </button>
                    </div>
                  </div>
                </div>
                <button mat-raised-button color="primary" type="button" (click)="addAiAgentConfig()">
                  Add config
                </button>
              </div>
              <div class="col-12" style="margin-top: 16px;">
                <div style="margin: 10px 0;font-weight: 600;">Clothier connection:</div>
                <pd-custom-select-without-lazy #clothierConnectionFilter
                  (loadDataEvent)="getClothierConnectionFilterData()"
                  (selectedItemEvent)="onSelectedItemForClothierConnection($event)"
                  (autoCompleteEvent)="getClothierConnectionAutocomplete($event)" [dataLabel]="'Select clothier'"
                  [startLabel]="'Type to search...'" [isMultiple]="false" [isNeedShowSelectAll]="false">
                </pd-custom-select-without-lazy>
              </div>
              }
            </div>
          </ng-container>
          }
        </div>
      </div>
      }
    </div>
    }
  </div>
  @if (isEaHasConnections) {
  <div>
    {{usersService.ClothierEaConnectionValidationMessage}}
  </div>
  }
</div>