import { AppRouterService } from '../app-router.service';
import { UserContextService } from './../user-context.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerService {
    constructor(
        private router: AppRouterService,
        private userContextService: UserContextService) { }

    public handle(response: Response, expectedErrors: string[]): string {
        let error = "";
        if (expectedErrors) {
            expectedErrors.forEach(expectedError => {
                if ((response as any).error.includes(expectedError)) {
                    error = expectedError;
                }
            });
        } else {
            if (response.status == 400) {
            }

            this.handleRestError(response.status, response.url);
        }
        return error;
    }

    private async handleRestError(status: number, path: string): Promise<void> {
        if (status === 401) {
            this.userContextService.resetContext();
            this.router.openLogin();
            if (!path.includes('refresh') && !path.includes('isAuth') && this.userContextService.isAuthorize.value) {
                alert('Http request error 401 UNAUTHORIZED')
            }
        } else if (status === 404) {
            alert('Http request error 404 NOT FOUND');
        } else if (status === 403) {
            alert('Access Denied');
        } else if (status === 0) {
            alert('Serve offline');
        } else if (status == 400) {
        } else if (status == 409) {
            alert('Conflict');
        }
        else {
            alert('Unexpected error, error code: ' + status);
        }
        throw new Error();
    }
}
