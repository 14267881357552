import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InputField } from '../models/InputField';
import { Role } from 'src/app/core/models/Role';
import { UsersService } from 'src/app/core/services/users.service';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { FieldRoleComponent } from '../fields/field-role/field-role.component';

@Component({
  selector: 'pd-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent {
  @ViewChild('fieldRole', { static: false }) fieldRole: FieldRoleComponent;

  public fullName: InputField = new InputField();
  public email: InputField = new InputField();
  public referralLink: InputField = new InputField();
  public password: InputField = new InputField();
  public confirmPassword: InputField = new InputField();
  public role: Role;

  public isLocal = false;
  public isTravel = false;
  public isTravelLocalIsValid = false;

  public exceptions: string[] = [];
  public spinner = false;
  public isShowForm = true;
  public isShowSuccessNewUserAlert = false;

  public newUserFullName: string;
  private timeout: any;

  constructor(private router: Router, private userService: UsersService) { }

  public getFullName(data: InputField): void {
    this.fullName = data;
    this.exceptions = [];
  }

  public getReferralLink(data: InputField): void {
    this.referralLink = data;
    this.exceptions = [];
  }

  public getIsLocal(data: any): void {
    this.isLocal = data.value;
    this.isTravelLocalIsValid = data.isValid;
    this.exceptions = [];
  }

  public getIsTravel(data: any): void {
    this.isTravel = data.value;
    this.isTravelLocalIsValid = data.isValid;
    this.exceptions = [];
  }

  public getEmail(data: InputField): void {
    this.email = data;
    this.exceptions = [];
  }

  public getRole(data: any) {
    this.role = data.role;
    this.exceptions = [];
  }

  public getPassword(data: InputField): void {
    this.password = data;
    this.exceptions = [];
  }

  public getConfirmPassword(data: InputField): void {
    this.confirmPassword = data;
    this.exceptions = [];
  }

  public resetData() {
    this.fullName = new InputField();
    this.email = new InputField();
    this.referralLink = new InputField();
    this.password = new InputField();
    this.confirmPassword = new InputField();
    this.role = undefined;
    this.exceptions = [];

    this.isLocal = false;
    this.isTravel = false;
    this.isTravelLocalIsValid = false;

    this.isShowForm = false;
    setTimeout(() => { this.isShowForm = true; }, 100);
  }

  public saveUser() {
    this.spinner = true;
    this.exceptions = [];

    let roleProperty = this.fieldRole.roleProperty;

    let newUser = {
      name: this.fullName.value,
      email: this.email.value,
      referralLink: this.referralLink.value,
      password: this.password.value,
      confirmPassword: this.confirmPassword.value,
      role_id: roleProperty.role.id,
      isSm: roleProperty.isSm,
      isTasksTab: roleProperty.isTasksTab,
      isUsersTab: roleProperty.isUsersTab,
      isAllowedExportLeadDate: roleProperty.isAllowedExportLeadDate,
      isAllowedHistoryOfLeads: roleProperty.isAllowedHistoryOfLeads,
      isRepoAccount: roleProperty.isRepoAccount,
      isWhatsappChat: roleProperty.isWhatsappChat,
      isGlobalChat: roleProperty.isGlobalChat,
      isAvatarEditor: roleProperty.isAvatarEditor,
      isSeeOthersCms: roleProperty.isSeeOthersCms,
      isInSmReport: roleProperty.isInSmReport,
      isNotInClothierReport: roleProperty.isNotInClothierReport,
      isMarketLeader: roleProperty.isMarketLeader,
      isAiAgent: roleProperty.isAiAgent,
      leaderOf: roleProperty.leaderOf,
      aiAgentConfigs: roleProperty.aiAgentConfigs,
      isLocal: (this.isShowTravelLocal) ? this.isLocal : false,
      isTravel: (this.isShowTravelLocal) ? this.isTravel : false
    };

    this.userService.addUser(newUser).then(
      (res) => {
        if (res.succeeded) {
          this.spinner = false;
          this.newUserFullName = this.fullName.value;
          this.resetData();
          this.showNewDealAlert();
        }
        else {
          this.spinner = false;
          res.errors.forEach(error => {
            this.newUserFullName = null;
            this.exceptions.push(error.description);
          });
        }
      },
      (error) => {
        if (error.status === 400) {
          this.newUserFullName = null;
          this.exceptions[0] = error.error.error;
        }
        this.spinner = false;
      });
  }

  public cancelUser() {
    this.router.navigate(['/Users']);
  }

  public get isShowTravelLocal(): boolean {
    return (this.role && (this.role.id === RolesEnum.Admin || this.role.id === RolesEnum.Clothier));
  }

  private showNewDealAlert() {
    this.isShowSuccessNewUserAlert = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => { this.isShowSuccessNewUserAlert = false }, 10000);
  }
}
