export class NoteModel {
    constructor(
        public id: number,
        public dealId: number,
        public text: string,
        public callSid: string,
        public created: Date,
        public createdBy: number,
        public createdByName: string
    ) { }
}