import { Injectable } from '@angular/core';
import { HttpRestService } from './http-client/http-rest-client.service';
import { BaseService } from './base.service';
import { CallEntity } from '../models/CallEntity';
import { OutboundCallRequest } from '../models/OutboundCallRequest';
import { OutboundCallResponse } from '../models/OutboundCallResponse';

export interface ElevenLabsConversationDetails {
  agent_id: string;
  conversation_id: string;
  status: string;

  transcript: {
    role: string;
    time_in_call_secs: number;
    message: string;
  }[];

  metadata: {
    start_time_unix_secs: number;
    call_duration_secs: number;
  };
}

export interface ElevenLabsAgent {
  agentId: string;
  agentName: string;
}

@Injectable()
export class CallsService extends BaseService {
  private readonly baseUrl = '/calls';

  constructor(private restService: HttpRestService) {
    super();
  }

  public async initiateCall(request: OutboundCallRequest): Promise<OutboundCallResponse> {
    const data = request;
    const url = `${this.baseUrl}/initiate`;
    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async getAllCalls(): Promise<CallEntity[]> {
    const url = `${this.baseUrl}`;
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async getCallById(id: number): Promise<CallEntity> {
    const url = `${this.baseUrl}/${id}`;
    return await this.handleResponse(this.restService.get({ url }));
  }

  public async getCallAudio(callSid: string): Promise<Blob> {
    const url = `${this.baseUrl}/call-audio/${callSid}`;
    return await this.handleResponse(
      this.restService.get({ url, responseType: 'blob' })
    );
  }

  public async getConversationAudio(conversationId: string): Promise<Blob> {
    const url = `${this.baseUrl}/conversation-audio/${conversationId}`;
    return await this.handleResponse(
      this.restService.get({ url, responseType: 'blob' })
    );
  }

  public async getConversationDetails(conversationId: string): Promise<ElevenLabsConversationDetails> {
    const url = `${this.baseUrl}/details/${conversationId}`;
    return await this.handleResponse(
      this.restService.get({ url })
    );
  }

  public async getAgents(): Promise<ElevenLabsAgent[]> {
    const url = `${this.baseUrl}/agents`;
    return await this.handleResponse(
      this.restService.get({ url })
    );
  }

  public async getVoiceNumbers(): Promise<string[]> {
    const url = `${this.baseUrl}/voice-numbers`;
    return await this.handleResponse(
      this.restService.get({ url })
    );
  }

  public async searchLinkedinUrl(dealId: number): Promise<any> {
    const url = `${this.baseUrl}/search-linkedin-url/${dealId}`;
    return await this.handleResponse(
      this.restService.get({ url })
    );
  }

  public async searchContacts(dealId: number): Promise<any> {
    const url = `${this.baseUrl}/search-contacts/${dealId}`;
    return await this.handleResponse(
      this.restService.get({ url })
    );
  }
}
