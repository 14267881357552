import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Role } from 'src/app/core/models/Role';
import { User } from 'src/app/core/models/UserModel';
import { RolePropertyModel, AiAgentConfigModel } from 'src/app/core/models/RolePropertyModel';
import { StageService } from 'src/app/core/services/stage.service';
import { UsersService } from 'src/app/core/services/users.service';
import { RoleService } from 'src/app/core/services/role.service';
import { CallsService, ElevenLabsAgent } from 'src/app/core/services/calls.service';
import { AgentTypes } from 'src/app/core/enums/AgentTypes';
import { STAGES_FOR_ALL_PIPELINES, STAGES_BY_ROLES_VISIBILITY } from 'src/assets/stages_new_prospespects';
import { GlobalConstants } from 'src/app/core/global-constants';
import { ObjectUtil } from 'src/app/core/utils/object.util';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { CustomSelectFilterWithoutLazyComponent } from 'src/app/shared/custom/custom-select-without-lazy/custom-select-without-lazy';

@Component({
  selector: 'pd-field-role',
  templateUrl: './field-role.component.html',
  styleUrls: ['./field-role.component.scss']
})
export class FieldRoleComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() roles: Role[];
  @Input() disabled = false;
  @Output() roleChange = new EventEmitter<Object>();
  public isEdit = true;
  public roleList: Role[] = [];
  public isEaHasConnections = false;
  public roleProperty: RolePropertyModel = new RolePropertyModel();
  private allFilteredUsers = [];
  private filteredUsers = [];
  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  @ViewChild('userFilter') set userFilterRef(content: CustomSelectFilterWithoutLazyComponent) {
    if (content) {
      this.customSelectUser = content;
      this.getUsersFilterData();
    }
  }
  public customSelectUser: CustomSelectFilterWithoutLazyComponent;
  public availableAgents: ElevenLabsAgent[] = [];
  public voiceNumbers: string[] = [];
  public agentTypeList = [
    { id: AgentTypes.CcAndSb, name: 'CC & SB' },
    { id: AgentTypes.Cc, name: 'CC' },
    { id: AgentTypes.Sb, name: 'SB' },
    { id: AgentTypes.Cold, name: 'Cold' }
  ];
  public clothierConnectionFilter: CustomSelectFilterWithoutLazyComponent;
  @ViewChild('clothierConnectionFilter') set clothierConnectionFilterRef(content: CustomSelectFilterWithoutLazyComponent) {
    if (content) {
      this.clothierConnectionFilter = content;
      this.getClothierConnectionFilterData();
    }
  }
  private allFilteredClothiers: User[] = [];
  private filteredClothiersMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  constructor(
    public usersService: UsersService,
    private userContextService: UserContextService,
    private stageService: StageService,
    private roleService: RoleService,
    private callsService: CallsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      ObjectUtil.safeUpdate(this.roleProperty, this.user);
      this.roleProperty.role = this.roles.find(x => x.id == this.user.role_id);
      this.roleChange.emit(this.roleProperty);
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.user && (this.user.role_id === RolesEnum.Admin || this.user.role_id === RolesEnum.EA || this.user.role_id === RolesEnum.EaCaller)) {
      const eaHasConnections = await this.usersService.checkIsEaHasConnections(this.user.id);
      this.isEaHasConnections = eaHasConnections;
    }
    if (!this.roles) {
      this.roles = await this.roleService.getAll();
    }
    this.availableAgents = await this.callsService.getAgents();
    this.voiceNumbers = await this.callsService.getVoiceNumbers();
    await this.initialiseStoreData();
    this.getUsersFilterData();
    this.roleChange.emit(this.roleProperty);
  }

  private async initialiseStoreData(): Promise<void> {
    const roles = [RolesEnum.Clothier];
    const result = await this.usersService.getUsers();
    this.allFilteredUsers = result.filter(x => roles.includes(x.role_id));
    this.roleProperty.role = this.user && this.roles && this.user.role_id ? this.roles.find(x => x.id == this.user.role_id) : null;
    if (this.user?.role_id) {
      const statuses = await this.stageService.getStagesWithExistingDealByUserIdObservable(this.user.id);
      this.roleList = this.getAllowedRolesForUpdateUser(this.roles, statuses, this.userContextService.user.value);
    } else {
      this.roleList = this.roles.filter(x => x.id != RolesEnum.MasterLead);
    }
    this.roleList = this.roleList.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getAllowedRolesForUpdateUser(allRoles: Role[], allStatuses: any[], currentUser: User): Role[] {
    if (!allRoles) return [];
    const filtered = STAGES_FOR_ALL_PIPELINES.filter(s => allStatuses.some(ds => ds.stage_id === s.id) && ![GlobalConstants.ALL_STAGES.Kill, GlobalConstants.ALL_STAGES.KilledRecord].includes(s.id));
    const allowedRoleIds = Object.entries(STAGES_BY_ROLES_VISIBILITY).reduce((acc, [roleName, stages]) => {
      const rid = RolesEnum[roleName];
      if (rid !== RolesEnum.MasterLead && stages && stages.length > 0 && (currentUser.role_id === rid || filtered.every(stage => stages.includes(stage.name)))) {
        acc.push(rid);
      }
      return acc;
    }, []);
    return allRoles.filter(role => allowedRoleIds.includes(role.id));
  }

  public addValue() {
    this.isEdit = true;
  }

  public cancelEdit() {
    this.isEdit = false;
  }

  public goToEdit() {
    this.isEdit = true;
  }

  public changeValue() {
    this.roleChange.emit(this.roleProperty);
  }

  public isRoleDisabled(role: Role): boolean {
    return this.isEaHasConnections && (role.id !== RolesEnum.Admin && role.id !== RolesEnum.EA && role.id !== RolesEnum.EaCaller);
  }

  public get isAllowedFlags(): boolean {
    return this.isAdmin || this.isClothier || this.isBB;
  }

  public get isAdmin(): boolean {
    return this.roleProperty.role && this.roleProperty.role.id === RolesEnum.Admin;
  }

  public get isClothier(): boolean {
    return this.roleProperty.role && this.roleProperty.role.id === RolesEnum.Clothier;
  }

  public get isBB(): boolean {
    return this.roleProperty.role && this.roleProperty.role.id === RolesEnum.BB;
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
      this.roleProperty.leaderOf.forEach(id => {
        const user = this.allFilteredUsers.find(x => x.id == id);
        if (user) {
          this.customSelectUser.selectItem(user, { checked: true });
        }
      });
      this.customSelectUser.closeCustomSelect();
    }
  }

  public getUsersAutocomplete(event: any) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    }
    search = search.toLowerCase();
    this.filteredUserMultiSelect.next(this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1));
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (!this.filteredUsers.includes(x[i])) {
          this.filteredUsers.push(x[i]);
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  public onSelectedItem() {
    this.roleProperty.leaderOf = this.customSelectUser.dataArray.map(u => u.id);
  }

  public onAgentChanged(config: AiAgentConfigModel) {
    const agent = this.availableAgents.find(a => a.agentId === config.agentId);
    config.agentName = agent ? agent.agentName : '';
  }

  public addAiAgentConfig() {
    const newConfig: AiAgentConfigModel = {
      agentTypeId: null,
      agentId: '',
      agentName: '',
      phone: ''
    };
    this.roleProperty.aiAgentConfigs.push(newConfig);
  }

  public removeAiAgentConfig(index: number) {
    this.roleProperty.aiAgentConfigs.splice(index, 1);
  }

  public isAgentTypeDisabled(agentTypeId: AgentTypes, currentIndex: number): boolean {
    return this.roleProperty.aiAgentConfigs.some((cfg, idx) => idx !== currentIndex && cfg.agentTypeId === agentTypeId);
  }

  public async getClothierConnectionFilterData(): Promise<void> {
    const allUsers = await this.usersService.getUsers();
    this.allFilteredClothiers = allUsers.filter(u => u.role_id === RolesEnum.Clothier || u.role_id === RolesEnum.Admin);
    this.filteredClothiersMultiSelect.next(this.allFilteredClothiers.slice());
    if (this.clothierConnectionFilter) {
      this.clothierConnectionFilter.setData(this.allFilteredClothiers);
      if (this.roleProperty.bbToClothierConnection) {
        const existingSelected = this.allFilteredClothiers.find(x => x.id === this.roleProperty.bbToClothierConnection);
        if (existingSelected) {
          this.clothierConnectionFilter.selectItem(existingSelected, { checked: true });
        }
      }
      this.clothierConnectionFilter.closeCustomSelect();
    }
  }

  public getClothierConnectionAutocomplete(event: any): void {
    let search = event.searchString;
    if (!search) {
      this.filteredClothiersMultiSelect.next(this.allFilteredClothiers.slice());
      if (this.clothierConnectionFilter) {
        this.clothierConnectionFilter.setData(this.allFilteredClothiers);
      }
      return;
    }
    search = search.toLowerCase();
    const filtered = this.allFilteredClothiers.filter(user => user.name.toLowerCase().includes(search));
    this.filteredClothiersMultiSelect.next(filtered);
    if (this.clothierConnectionFilter) {
      this.clothierConnectionFilter.setData(filtered);
    }
  }

  public onSelectedItemForClothierConnection(): void {
    const selected = this.clothierConnectionFilter.dataArray;
    if (selected && selected.length) {
      this.roleProperty.bbToClothierConnection = selected[0].id;
    } else {
      this.roleProperty.bbToClothierConnection = null;
    }
  }
}
